import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { format } from 'date-fns';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Checkbox,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  ScrollArea,
  Separator,
} from '@purple/ui';
// hooks
import { useModal } from '~/hooks';
import { useCreateNote } from '~/queries';
// helpers
import { ModalType } from '~/constants/modals';
import { ReadableTakeActionType } from '~/constants/take-action';
// types
import type React from 'react';
import type { TGroupActionDetails } from '@purple/shared-types';

const addNoteSchema = z.object({
  text: z.string().trim().min(1, 'Note text is required'),
  is_private: z.boolean(),
});

type TFormSchema = z.infer<typeof addNoteSchema>;

type TGroupActionAddNoteDialogProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupActionAddNoteDialog: React.FC<TGroupActionAddNoteDialogProperties> = (props) => {
  const { groupAction } = props;

  const { isOpen, toggleModal } = useModal(ModalType.GROUP_ACTION_ADD_NOTE);
  const { mutate: createNote, isPending } = useCreateNote({ showNotifications: true });

  const defaultValues = useMemo(
    () => ({
      text: '',
      is_private: false,
    }),
    [],
  );

  const form = useForm<TFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(addNoteSchema),
    defaultValues,
  });

  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        blockquote: false,
        bold: false,
        italic: false,
        bulletList: false,
        orderedList: false,
        heading: false,
        code: false,
        dropcursor: false,
        listItem: false,
        strike: false,
        gapcursor: false,
        history: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
      }),
      Placeholder.configure({
        placeholder: () => 'Enter a note here',
      }),
    ],
    editorProps: {
      attributes: {
        id: groupAction.id,
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: form.getValues('text'),
    onUpdate: ({ editor }) => {
      const isEmpty = editor.getText().trim().length === 0;
      form.setValue('text', isEmpty ? '' : JSON.stringify(editor.getJSON()), { shouldValidate: true });
    },
  });

  const closeNoteDialog = () => {
    toggleModal(false);
    editorConfig?.commands.setContent('');
  };

  const dialogOpenChangeHandler = (open: boolean) => {
    if (open) {
      toggleModal(true);
    } else {
      closeNoteDialog();
    }
  };

  const saveNoteClickHandler = (data: TFormSchema) => {
    const title = `[${data.is_private ? 'My Notes' : 'Shared Notes'}] ${ReadableTakeActionType[groupAction.record_action_type]} ${format(new Date(), 'MM/dd/yyyy')}`;
    createNote(
      {
        content_type: 'actiongroup',
        object_id: groupAction.id,
        text: data.text,
        is_private: data.is_private,
        title,
      },
      {
        onSuccess: (noteData) => {
          toggleModal(false);
          editorConfig?.commands.setContent(JSON.parse(noteData.text) as string);
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={dialogOpenChangeHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-full max-w-[564px] flex-col">
        <DialogHeader className="flex-row items-start justify-between gap-2">
          <div className="flex w-full flex-col gap-1">
            <DialogTitle className="text-left text-lg font-semibold text-grey-title">New Note</DialogTitle>
            <DialogDescription className="text-left text-sm text-grey-600">
              Related to:
              {' '}
              <strong className="font-semibold">{groupAction.details.title}</strong>
            </DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea
          type="auto"
          className="flex size-full max-h-[640px] min-h-[160px] flex-col px-6 py-4"
          scrollBarClassName="p-2 w-[22px]"
        >
          <Form providerProps={form} className="flex w-full flex-col gap-4">
            <FormField
              control={form.control}
              name="text"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Note Text</FormLabel>
                  <FormControl>
                    <EditorContent
                      ref={field.ref}
                      editor={editorConfig}
                      className={cn(
                        'minimal-tiptap-editor flex h-full min-h-[104px] w-full cursor-text flex-col rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 transition-colors duration-200 focus-within:border-brand-blue-700 hover:border-brand-blue-700 focus:border-brand-blue-700',
                        {
                          'border-error-main': form.formState.errors.text,
                        },
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_private"
              render={({ field }) => (
                <FormItem className="flex gap-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value={field.value.toString()}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="relative top-0.5 size-4"
                    />
                  </FormControl>
                  <div className="flex flex-col">
                    <FormLabel className="text-sm font-medium">Mark as my note</FormLabel>
                    <FormDescription className="text-sm font-normal">
                      This note will be visible to you only. Other team members will not be able to see it.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />
          </Form>
        </ScrollArea>
        <Separator />
        <DialogFooter>
          <Button type="button" variant="tertiary" onClick={closeNoteDialog}>
            Cancel
          </Button>
          <Button
            type="button"
            variant="primary"
            isLoading={isPending}
            disabled={isPending}
            onClick={form.handleSubmit(saveNoteClickHandler)}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
