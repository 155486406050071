import { useCallback, useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { UserSearchActionChoice } from '@purple/shared-types';
import { getInitialsFromName } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useAddPurpleUserParticipants, useUsersSearch } from '~/queries';
import type React from 'react';
import type { TActionPurpleUserParticipant } from '@purple/shared-types';

const addParticipantsSchema = z.object({
  purpleParticipants: z.array(z.string()).min(1, {
    message: 'At least one participant is required.',
  }),
});

type TAddParticipantsDialogProperties = {
  actionId: string;
  existingParticipants: TActionPurpleUserParticipant[];
};

export const AddParticipantsDialog: React.FC<TAddParticipantsDialogProperties> = (props) => {
  const { actionId, existingParticipants } = props;

  const formId = useId();

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');
  const { isOpen, toggleModal, closeModal } = useModal(ModalType.ADD_ACTION_PARTICIPANTS);
  const { mutate: addUsers, isPending } = useAddPurpleUserParticipants(actionId);
  const { data: participants, isFetching } = useUsersSearch({
    search: debouncedSearchValue,
    action_choices: UserSearchActionChoice.PURPLE_USER_PARTICIPANTS,
    enabled: isOpen,
  });

  const purpleParticipantsOptions = useMemo(
    () =>
      participants?.results
        .map((item) => ({
          ...item,
          label: item.full_name ?? item.email,
          value: item.id,
        }))
        .filter((user) => !existingParticipants.some((participant) => participant.id === user.id)) ?? [],
    [participants, existingParticipants],
  );

  const form = useForm<z.infer<typeof addParticipantsSchema>>({
    resolver: zodResolver(addParticipantsSchema),
    mode: 'onChange',
    defaultValues: {
      purpleParticipants: [],
    },
  });

  const addParticipantsSubmitHandler = (data: z.infer<typeof addParticipantsSchema>) => {
    addUsers(
      {
        purple_user_participants: data.purpleParticipants,
      },
      {
        onSuccess: () => {
          closeModal();
          form.reset();
          setDebouncedSearchValue('');
        },
      },
    );
  };

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  const toggleModalChange = useCallback(
    (open: boolean) => {
      if (!open) {
        form.reset();
        setDebouncedSearchValue('');
      }
      toggleModal(open);
    },
    [form, toggleModal],
  );

  return (
    <Dialog open={isOpen} onOpenChange={toggleModalChange}>
      <DialogContent className="w-full max-w-[564px]">
        <DialogHeader className="flex-row items-center justify-between px-6 py-4">
          <DialogTitle className='className="leading-7 tracking-normal'>Add Purple Users</DialogTitle>
          <DialogDescription className="sr-only">Select Purple users to add to this action session.</DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <div className="flex w-full flex-col gap-1 p-6">
          <Form providerProps={form} id={formId} onSubmit={form.handleSubmit(addParticipantsSubmitHandler)}>
            <FormField
              control={form.control}
              name="purpleParticipants"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Purple Users</FormLabel>
                  <FormControl>
                    <MultiSelect
                      {...field}
                      isError={!!form.formState.errors.purpleParticipants}
                      selectedOptions={purpleParticipantsOptions.filter((option) => field.value.includes(option.value))}
                      placeholder="Select users"
                      showSearch
                      modalPopover
                      loading={isFetching}
                      shouldFilter={false}
                      onSearchChange={debouncedSearch}
                      onOptionChange={field.onChange}
                    >
                      {purpleParticipantsOptions.map((option) => (
                        <MultiSelectItem
                          key={option.value}
                          value={option.value}
                          option={option}
                          isSelected={field.value.includes(option.value)}
                          customContent
                        >
                          <>
                            {field.value.includes(option.value) && (
                              <PurpleIcon
                                name="check"
                                className="absolute left-4 top-1/2 size-4 -translate-y-1/2 text-brand-blue-700"
                              />
                            )}
                            <div className="flex items-center gap-2">
                              <Avatar size={32}>
                                <AvatarImage src={option.avatar ?? undefined} alt={option.full_name ?? option.email} />
                                <AvatarFallback className="bg-grey-200">
                                  {getInitialsFromName(option.full_name ?? '- -')}
                                </AvatarFallback>
                              </Avatar>
                              <div className="flex flex-col gap-1">
                                <strong className="line-clamp-1 text-xs font-medium text-grey-950">
                                  {option.full_name ?? option.email}
                                </strong>
                                <span className="line-clamp-1 text-xs text-grey-600">
                                  {option.prounitas_roles.at(0) ?? 'No Role'}
                                </span>
                              </div>
                            </div>
                          </>
                        </MultiSelectItem>
                      ))}
                    </MultiSelect>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </Form>
        </div>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button form={formId} type="submit" variant="primary" disabled={isPending} isLoading={isPending}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
