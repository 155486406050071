import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { cn } from '@purple/ui';

type TLinkButtonProperties = React.ComponentProps<typeof Link> & {
  /**
   * The name of the icon to display before the button text.
   */
  iconName?: React.ComponentProps<typeof PurpleIcon>['name'];
  /**
   * Whether the link is disabled.
   * @default false
   */
  disabled?: boolean;
};

export const LinkButton = forwardRef<HTMLAnchorElement, TLinkButtonProperties>((props, reference) => {
  const { iconName, className, children, disabled = false, ...rest } = props;

  return (
    <Link
      ref={reference}
      className={cn(
        'inline-flex w-fit items-center gap-1 px-1 py-0.5 font-primary text-sm font-medium text-brand-blue-700 outline-none transition-colors duration-200 hover:text-brand-blue-600 focus:text-brand-blue-600 focus-visible:text-brand-blue-600 active:text-brand-blue-800 active:transition-none',
        { 'pointer-events-none text-grey-400': disabled },
        className,
      )}
      {...rest}
    >
      {iconName && <PurpleIcon name={iconName} className="size-4 shrink-0" />}
      {children}
    </Link>
  );
});
LinkButton.displayName = 'LinkButton';
