import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMask } from '@react-input/mask';
import { PurpleIcon } from '@purple/icons';
import { ActivityType, ActivityWeeklyOccurrence } from '@purple/shared-types';
import { CONTAIN_DIGIT_REGEX, getInitialsFromName, isFieldExist, mergeReferences } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  cn,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DatePicker,
  DescriptionDetails,
  DescriptionItem,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
// hooks
import { useDebouncedCallback } from '@purple/hooks';
import { useCurrentUser } from '~/hooks';
import {
  useActivityChoices,
  useMySchools,
  useSchoolList,
  useUpdateActivity,
  useUpdateRecurringActivity,
  useUsersSearch,
} from '~/queries';
// helpers
import { ALLOWED_TOPIC_TYPES, ReadableActivityType } from '~/constants/create-activity';
import { PHONE_MASK } from '~/constants/phone';
import { DAYS_OF_WEEK_OPTIONS } from '~/constants/reminder';
import { ActivityDetailsInfo } from './ActivityDetailsInfo';
// components
import { ActivitySectionHeader } from './ActivitySectionHeader';
// schema
import { activityDetailsSchema } from './schema';
// types
import type React from 'react';
import type { z } from 'zod';
import type { TActivityInfo } from '@purple/shared-types';

type TActivityDetailsSectionProperties = {
  activity: TActivityInfo;
};

export const ActivityDetailsSection: React.FC<TActivityDetailsSectionProperties> = (props) => {
  const { activity } = props;
  const { id, details } = activity;

  const inputReference = useMask({
    mask: PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [debouncedSchoolSearchValue, setDebouncedSchoolSearchValue] = useState<string>('');
  const [debouncedAllSchoolSearchValue, setDebouncedAllSchoolSearchValue] = useState<string>('');
  const [debouncedUserSearchValue, setDebouncedUserSearchValue] = useState<string>('');
  const [debouncedParticipantSearchValue, setDebouncedParticipantSearchValue] = useState<string>('');
  const debouncedSchoolSearch = useDebouncedCallback(setDebouncedSchoolSearchValue, 500);
  const debouncedAllSchoolSearch = useDebouncedCallback(setDebouncedAllSchoolSearchValue, 500);
  const debouncedUserSearch = useDebouncedCallback(setDebouncedUserSearchValue, 500);
  const debouncedParticipantSearch = useDebouncedCallback(setDebouncedParticipantSearchValue, 500);

  const {
    typeOptions,
    serviceCategoriesOptions,
    stakeholdersServedOptions,
    gradeLevelsOptions,
    campusContactTitlesOptions,
    crisisGradesOptions,
    crisisTypesOptions,
    topicsOptions,
  } = useActivityChoices({
    community_activity_type: details.community_activity_type.name,
    enabled: isEditing,
  });
  const { data: schoolData, isFetching: isSchoolsFetching } = useMySchools({
    search: debouncedSchoolSearchValue,
  });
  const { data: allSchoolData, isFetching: isAllSchoolsFetching } = useSchoolList({
    search: debouncedAllSchoolSearchValue,
    enabled: isFieldExist(details.supported_school),
  });
  const { data: purpleUsers, isFetching: isUsersFetching } = useUsersSearch({
    search: debouncedUserSearchValue,
    school_access: details.school.id,
    enabled: details.community_activity_type.name === ActivityType.CRISIS_CALL_TRACKER,
  });
  const { data: participants, isFetching: isParticipantsFetching } = useUsersSearch({
    search: debouncedParticipantSearchValue,
    enabled: details.community_activity_type.name === ActivityType.CRISIS_RESPONSE_BEHAVIORAL_SUPPORT,
  });
  const { mutate: updateActivity, isPending } = useUpdateActivity();
  const { mutate: updateRecurringActivity, isPending: isRecurringPending } = useUpdateRecurringActivity();
  const { user } = useCurrentUser();
  const allowedToEdit = useMemo(() => user.id === details.created_by?.id, [user, details.created_by?.id]);

  const schoolsOptions = useMemo(
    () =>
      schoolData?.results.map((school) => ({
        label: school.name,
        value: school.id,
      })) ?? [],
    [schoolData?.results],
  );
  const supportedSchoolsOptions = useMemo(
    () =>
      allSchoolData?.results.map((school) => ({
        label: school.name,
        value: school.id,
      })) ?? [],
    [allSchoolData?.results],
  );
  const usersOptions = useMemo(
    () =>
      purpleUsers?.results.map((item) => ({
        ...item,
        label: item.full_name ?? item.email,
        value: item.id,
      })) ?? [],
    [purpleUsers],
  );
  const participantsOptions = useMemo(
    () =>
      participants?.results.map((item) => ({
        ...item,
        label: item.full_name ?? item.email,
        value: item.id,
      })) ?? [],
    [participants],
  );

  const defaultValues: z.infer<typeof activityDetailsSchema> = useMemo(
    () => ({
      isRecurring: details.recurring_group !== null,
      type: details.type,
      schoolId: details.school.id,
      supportedSchoolId: details.supported_school?.id,
      date: details.recurring_group
        ? new Date(details.recurring_group.start_date_and_time)
        : details.date_and_time
          ? new Date(details.date_and_time)
          : undefined,
      endDate: details.recurring_group?.end_date_and_time
        ? new Date(details.recurring_group.end_date_and_time)
        : undefined,
      duration: details.duration,
      services: details.service_categories?.map((service) => service.id),
      stakeholders: details.stakeholders_served,
      numberOfPeopleServed: details.number_of_people_served,
      quantityOfResourcesProvided: details.quantity_of_resources_provided,
      bagsProvidedCount: details.bags_provided_count,
      participantsCount: details.participants_count,
      gradeLevel: details.grade_level,
      crisisTypes: details.crisis_type,
      campusContactTitle: details.campus_contact_title,
      campusContactName: details.campus_contact_name,
      contactEmail: details.contact_email,
      contactPhoneNumber: details.contact_phone_number,
      crisisGradeLevel: details.crisis_grade_level,
      assignedTo: details.assigned_to?.id,
      sessionParticipants: details.session_participant?.id,
      topic: details.topic,
      daysOfWeek: details.recurring_group?.days_of_week,
      interval: details.recurring_group?.weekly_interval,
    }),
    [details],
  );

  const form = useForm<z.infer<typeof activityDetailsSchema>>({
    resolver: zodResolver(activityDetailsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const formType = form.watch('type');
  const isTopicDisabled = useMemo(() => typeof formType === 'string' && !ALLOWED_TOPIC_TYPES.has(formType), [formType]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof activityDetailsSchema>, recurringId?: string) => {
    const updateCallback = recurringId ? updateRecurringActivity : updateActivity;
    const entityId = recurringId ?? id;

    updateCallback(
      {
        id: entityId,
        name: activity.name,
        ...(details.community_activity_type.name !== ActivityType.CRISIS_CALL_TRACKER && {
          description: activity.details.description_of_crisis,
        }),
        details: {
          school: formData.schoolId ?? details.school.id,
          ...(isFieldExist(details.type) && { type: formData.type }),
          ...(isFieldExist(details.date_and_time) && formData.date && { date_and_time: formData.date.toISOString() }),
          ...(isFieldExist(details.duration) && { duration: formData.duration }),
          ...(isFieldExist(details.service_categories) && { service_categories: formData.services }),
          ...(isFieldExist(details.stakeholders_served) && { stakeholders_served: formData.stakeholders }),
          ...(isFieldExist(details.number_of_people_served) && {
            number_of_people_served: formData.numberOfPeopleServed,
          }),
          ...(isFieldExist(details.quantity_of_resources_provided) && {
            quantity_of_resources_provided: formData.quantityOfResourcesProvided,
          }),
          ...(isFieldExist(details.bags_provided_count) && { bags_provided_count: formData.bagsProvidedCount }),
          ...(isFieldExist(details.participants_count) && { participants_count: formData.participantsCount }),
          ...(isFieldExist(details.grade_level) && { grade_level: formData.gradeLevel }),
          ...(isFieldExist(details.crisis_type) && { crisis_type: formData.crisisTypes }),
          ...(isFieldExist(details.campus_contact_title) && { campus_contact_title: formData.campusContactTitle }),
          ...(isFieldExist(details.campus_contact_name) && { campus_contact_name: formData.campusContactName }),
          ...(isFieldExist(details.contact_email) && { contact_email: formData.contactEmail }),
          ...(isFieldExist(details.contact_phone_number) && { contact_phone_number: formData.contactPhoneNumber }),
          ...(isFieldExist(details.crisis_grade_level) && { crisis_grade_level: formData.crisisGradeLevel }),
          ...(isFieldExist(details.assigned_to) && { assigned_to: formData.assignedTo }),
          ...(isFieldExist(details.session_participant) && { session_participant: formData.sessionParticipant }),
          ...(isFieldExist(details.topic) && { topic: formData.topic }),
        },
        ...(formData.isRecurring && {
          weekly_interval: formData.interval,
          days_of_week: formData.daysOfWeek,
          ...(formData.date && { start_date_and_time: formData.date.toISOString() }),
          ...(formData.endDate && { end_date_and_time: formData.endDate.toISOString() }),
        }),
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-1">
      <ActivitySectionHeader
        editing={isEditing}
        loading={isPending}
        recurringLoading={isRecurringPending}
        allowRecurring={details.recurring_group !== null}
        disableEditing={!allowedToEdit}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit((data) => saveDetailsClickHandler(data))}
        onRecurringSave={form.handleSubmit((data) => saveDetailsClickHandler(data, details.recurring_group?.id))}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-1">
              <DescriptionItem tag="p">
                <DescriptionTerm tag="span">Record Type</DescriptionTerm>
                <DescriptionDetails tag="span">
                  {ReadableActivityType[details.community_activity_type.name]}
                </DescriptionDetails>
              </DescriptionItem>
              {isFieldExist(details.type) && (
                <FormField
                  control={form.control}
                  name="type"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Type</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select type"
                              selectedLabel={typeOptions.find((option) => option.value === field.value)?.label}
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent searchPlaceholder="Search type..." emptyContent="Type not found.">
                            {typeOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={(typeValue) => {
                                  if (!ALLOWED_TOPIC_TYPES.has(typeValue)) {
                                    form.setValue('topic', null);
                                  }
                                  field.onChange(typeValue);
                                }}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.topic) && (
                <FormField
                  control={form.control}
                  name="topic"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Topic</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select topic"
                              selectedLabel={topicsOptions.find((option) => option.value === field.value)?.label}
                              disabled={isTopicDisabled}
                            />
                          </FormControl>
                          <ComboBoxContent searchPlaceholder="Search topic..." emptyContent="Topic not found.">
                            {topicsOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.crisis_type) && (
                <FormField
                  control={form.control}
                  name="crisisTypes"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Crisis Types</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <MultiSelect
                            {...field}
                            isError={!!form.formState.errors.crisisTypes}
                            selectedOptions={crisisTypesOptions.filter((item) => field.value?.includes(item.value))}
                            placeholder="Select crisis types"
                            classNames={{ trigger: 'min-h-9 py-1' }}
                            modalPopover
                            showSearch
                            onOptionChange={field.onChange}
                          >
                            {crisisTypesOptions.map((option) => (
                              <MultiSelectItem
                                key={option.value}
                                value={option.value}
                                option={option}
                                isSelected={field.value?.includes(option.value)}
                              />
                            ))}
                          </MultiSelect>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.school) && (
                <FormField
                  control={form.control}
                  name="schoolId"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">School</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select school"
                              selectedLabel={
                                schoolsOptions.find((option) => option.value === field.value)?.label ?? details.school?.name
                              }
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent
                            shouldFilter={false}
                            loading={isSchoolsFetching}
                            searchPlaceholder="Search school..."
                            emptyContent="School not found."
                            onSearchChange={debouncedSchoolSearch}
                          >
                            {schoolsOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.campus_contact_title) && (
                <FormField
                  control={form.control}
                  name="campusContactTitle"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Campus Contact Title
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select title"
                              selectedLabel={
                                campusContactTitlesOptions.find((option) => option.value === field.value)?.label
                                ?? details.campus_contact_title
                              }
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent searchPlaceholder="Search title..." emptyContent="Title not found.">
                            {campusContactTitlesOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                keywords={[label, value]}
                                onSelect={field.onChange}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.campus_contact_name) && (
                <FormField
                  control={form.control}
                  name="campusContactName"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Campus Contact Name
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <Input
                            {...field}
                            type="text"
                            isError={!!fieldState.error}
                            placeholder="Enter name"
                            className="max-h-9"
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.contact_email) && (
                <FormField
                  control={form.control}
                  name="contactEmail"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Contact Email</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <Input
                            {...field}
                            type="email"
                            isError={!!fieldState.error}
                            placeholder="Enter email"
                            className="max-h-9"
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.contact_phone_number) && (
                <FormField
                  control={form.control}
                  name="contactPhoneNumber"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Contact Phone Number
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <Input
                            {...field}
                            ref={mergeReferences([field.ref, inputReference])}
                            isError={!!fieldState.error}
                            placeholder={PHONE_MASK}
                            type="tel"
                            className="max-h-9"
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.crisis_grade_level) && (
                <FormField
                  control={form.control}
                  name="crisisGradeLevel"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Crisis Grade Level</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select grade"
                              selectedLabel={
                                crisisGradesOptions.find((option) => option.value === field.value)?.label
                                ?? details.crisis_grade_level
                              }
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent searchPlaceholder="Search grade..." emptyContent="Grade not found.">
                            {crisisGradesOptions
                              .sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }))
                              .map(({ label, value }) => (
                                <ComboBoxItem
                                  key={value}
                                  value={value}
                                  selected={value === field.value}
                                  keywords={[label, value]}
                                  onSelect={field.onChange}
                                >
                                  {label}
                                </ComboBoxItem>
                              ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.assigned_to) && (
                <FormField
                  control={form.control}
                  name="assignedTo"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Assigned to</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select user"
                              selectedLabel={
                                usersOptions.find((option) => option.value === field.value)?.label
                                ?? details.campus_contact_title
                              }
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent
                            shouldFilter={false}
                            loading={isUsersFetching}
                            onSearchChange={debouncedUserSearch}
                            searchPlaceholder="Search user..."
                            emptyContent="User not found."
                          >
                            {usersOptions.map(({ value, avatar, full_name, email }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                                asChild
                              >
                                <div className="flex items-center gap-1">
                                  {field.value?.includes(value) && (
                                    <PurpleIcon
                                      name="check"
                                      className="absolute left-4 top-1/2 size-4 -translate-y-1/2 text-brand-blue-700"
                                    />
                                  )}
                                  <div className="flex items-center gap-2">
                                    <Avatar size={32}>
                                      <AvatarImage src={avatar ?? undefined} alt={full_name ?? email} />
                                      <AvatarFallback className="bg-grey-200">
                                        {getInitialsFromName(full_name ?? '- -')}
                                      </AvatarFallback>
                                    </Avatar>
                                    <div className="flex flex-col gap-1">
                                      <strong className="line-clamp-1 text-xs font-medium text-grey-950">
                                        {full_name ?? email}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {(isFieldExist(details.date_and_time) || isFieldExist(details.recurring_group?.start_date_and_time)) && (
                <FormField
                  control={form.control}
                  name="date"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        {details.recurring_group ? 'Start Date & Time' : 'Date & Time'}
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <DatePicker
                            mode="single"
                            placeholder="Select date & time"
                            formatterString="MMM dd, yyyy, h:mm a"
                            isError={!!fieldState.error}
                            triggerDisabled={field.disabled}
                            selected={field.value}
                            defaultMonth={field.value}
                            captionLayout="dropdown"
                            onDayClick={field.onChange}
                            onTimeChange={field.onChange}
                            triggerClassName="max-h-9"
                            contentClassName="min-w-[320px]"
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.recurring_group?.end_date_and_time) && (
                <FormField
                  control={form.control}
                  name="endDate"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">End Date & Time</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <DatePicker
                            mode="single"
                            placeholder="Select date & time"
                            formatterString="MMM dd, yyyy, h:mm a"
                            isError={!!fieldState.error}
                            triggerDisabled={field.disabled}
                            selected={field.value}
                            defaultMonth={field.value}
                            captionLayout="dropdown"
                            onDayClick={field.onChange}
                            onTimeChange={field.onChange}
                            triggerClassName="max-h-9"
                            contentClassName="min-w-[320px]"
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.duration) && (
                <FormField
                  control={form.control}
                  name="duration"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Duration</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!form.formState.errors.duration}
                            placeholder="Enter duration"
                            className="max-h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.stakeholders_served) && (
                <FormField
                  control={form.control}
                  name="stakeholders"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Stakeholders Served
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <MultiSelect
                            {...field}
                            isError={!!form.formState.errors.stakeholders}
                            selectedOptions={stakeholdersServedOptions.filter((item) => field.value?.includes(item.value))}
                            placeholder="Select stakeholders"
                            classNames={{ trigger: 'min-h-9 py-1' }}
                            modalPopover
                            showSearch
                            onOptionChange={field.onChange}
                          >
                            {stakeholdersServedOptions.map((option) => (
                              <MultiSelectItem
                                key={option.value}
                                value={option.value}
                                option={option}
                                isSelected={field.value?.includes(option.value)}
                              />
                            ))}
                          </MultiSelect>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.service_categories) && (
                <FormField
                  control={form.control}
                  name="services"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Service Categories</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <MultiSelect
                            {...field}
                            isError={!!form.formState.errors.services}
                            selectedOptions={serviceCategoriesOptions.filter((service) =>
                              field.value?.includes(service.value),
                            )}
                            placeholder="Select categories"
                            classNames={{ trigger: 'min-h-9 py-1' }}
                            modalPopover
                            showSearch
                            onOptionChange={field.onChange}
                          >
                            {serviceCategoriesOptions.map((option) => (
                              <MultiSelectItem
                                key={option.value}
                                value={option.value}
                                option={option}
                                isSelected={field.value?.includes(option.value)}
                              />
                            ))}
                          </MultiSelect>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.number_of_people_served) && (
                <FormField
                  control={form.control}
                  name="numberOfPeopleServed"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Number of People Served
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!form.formState.errors.duration}
                            placeholder="Enter number of people served"
                            className="max-h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.quantity_of_resources_provided) && (
                <FormField
                  control={form.control}
                  name="quantityOfResourcesProvided"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Quantity of Resources Provided
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!form.formState.errors.duration}
                            placeholder="Enter quantity of resources provided"
                            className="max-h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.bags_provided_count) && (
                <FormField
                  control={form.control}
                  name="bagsProvidedCount"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Quantity of Bags Provided
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!form.formState.errors.duration}
                            placeholder="Enter quantity of bags provided"
                            className="max-h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.supported_school) && (
                <FormField
                  control={form.control}
                  name="supportedSchoolId"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Supported School</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select school"
                              selectedLabel={supportedSchoolsOptions.find((option) => option.value === field.value)?.label}
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent
                            shouldFilter={false}
                            loading={isAllSchoolsFetching}
                            searchPlaceholder="Search school..."
                            emptyContent="School not found."
                            onSearchChange={debouncedAllSchoolSearch}
                          >
                            {supportedSchoolsOptions.map(({ label, value }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={(schoolId) =>
                                  schoolId === field.value ? field.onChange(null) : field.onChange(schoolId)}
                              >
                                {label}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.grade_level) && (
                <FormField
                  control={form.control}
                  name="gradeLevel"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Grade Level</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <MultiSelect
                            {...field}
                            isError={!!form.formState.errors.gradeLevel}
                            selectedOptions={gradeLevelsOptions.filter((item) => field.value?.includes(item.value))}
                            placeholder="Select grades"
                            classNames={{ trigger: 'min-h-9 py-1' }}
                            modalPopover
                            showSearch
                            onOptionChange={field.onChange}
                          >
                            {gradeLevelsOptions
                              .sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }))
                              .map((option) => (
                                <MultiSelectItem
                                  key={option.value}
                                  value={option.value}
                                  option={option}
                                  isSelected={field.value?.includes(option.value)}
                                />
                              ))}
                          </MultiSelect>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.participants_count) && (
                <FormField
                  control={form.control}
                  name="participantsCount"
                  render={({ field }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Number of Participants
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!form.formState.errors.duration}
                            placeholder="Enter number of participants"
                            className="max-h-9"
                            min={0}
                            changeOnWheel
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.session_participant) && (
                <FormField
                  control={form.control}
                  name="sessionParticipant"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">
                        Session Participant
                      </FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <ComboBox modal>
                          <FormControl>
                            <ComboBoxTrigger
                              isError={!!fieldState.error}
                              placeholder="Select participant"
                              selectedLabel={
                                participantsOptions.find((option) => option.value === field.value)?.label
                                ?? details.session_participant?.full_name
                              }
                              className="max-h-9"
                            />
                          </FormControl>
                          <ComboBoxContent
                            shouldFilter={false}
                            loading={isParticipantsFetching}
                            onSearchChange={debouncedParticipantSearch}
                            searchPlaceholder="Search user..."
                            emptyContent="User not found."
                          >
                            {participantsOptions.map(({ value, avatar, full_name, email }) => (
                              <ComboBoxItem
                                key={value}
                                value={value}
                                selected={value === field.value}
                                onSelect={field.onChange}
                                asChild
                              >
                                <div className="flex items-center gap-1">
                                  {field.value?.includes(value) && (
                                    <PurpleIcon
                                      name="check"
                                      className="absolute left-4 top-1/2 size-4 -translate-y-1/2 text-brand-blue-700"
                                    />
                                  )}
                                  <div className="flex items-center gap-2">
                                    <Avatar size={32}>
                                      <AvatarImage src={avatar ?? undefined} alt={full_name ?? email} />
                                      <AvatarFallback className="bg-grey-200">
                                        {getInitialsFromName(full_name ?? '- -')}
                                      </AvatarFallback>
                                    </Avatar>
                                    <div className="flex flex-col gap-1">
                                      <strong className="line-clamp-1 text-xs font-medium text-grey-950">
                                        {full_name ?? email}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </ComboBoxItem>
                            ))}
                          </ComboBoxContent>
                        </ComboBox>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.recurring_group?.weekly_interval) && (
                <FormField
                  control={form.control}
                  name="interval"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Weekly Occurrence</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <RadixSelect onValueChange={field.onChange} value={field.value}>
                          <FormControl>
                            <RadixSelectTrigger
                              className={cn('max-h-9 capitalize', {
                                'border-error-main': !!fieldState.error,
                              })}
                            >
                              <RadixSelectValue placeholder="Select weekly occurrence" />
                            </RadixSelectTrigger>
                          </FormControl>
                          <RadixSelectContent>
                            {Object.values(ActivityWeeklyOccurrence).map((value) => (
                              <RadixSelectItem key={value} value={value} className="capitalize">
                                {value}
                              </RadixSelectItem>
                            ))}
                          </RadixSelectContent>
                        </RadixSelect>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
              {isFieldExist(details.recurring_group?.days_of_week) && (
                <FormField
                  control={form.control}
                  name="daysOfWeek"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
                      <FormLabel className="font-primary text-base font-normal text-grey-600">Days of Week</FormLabel>
                      <div className={cn('flex w-full max-w-[320px] flex-col gap-1 xl:max-w-[256px] xl:flex-1')}>
                        <FormControl>
                          <MultiSelect
                            {...field}
                            isError={!!fieldState.error}
                            modalPopover
                            showSearch
                            selectedOptions={DAYS_OF_WEEK_OPTIONS.filter((option) => field.value?.includes(option.value))}
                            placeholder="Select days"
                            classNames={{ trigger: 'min-h-9 py-1' }}
                            onOptionChange={field.onChange}
                          >
                            {DAYS_OF_WEEK_OPTIONS.map((option) => (
                              <MultiSelectItem
                                key={option.value}
                                value={option.value}
                                option={option}
                                isSelected={field.value?.includes(option.value)}
                              />
                            ))}
                          </MultiSelect>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              )}
            </Form>
          )
        : (
            <ActivityDetailsInfo activity={activity} />
          )}
    </div>
  );
};
