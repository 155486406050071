import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { convertStringToEditorJson, getInitialsFromName } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  Textarea,
} from '@purple/ui';
// hooks
import { useActionLinkedStudents, useCreateRecurrence } from '~/queries';
// schema
import { addRecurrenceSchema } from './schema';
// types
import type React from 'react';
import type { z } from 'zod';
import type { TGroupActionDetails } from '@purple/shared-types';

const DEFAULT_LINKED_STUDENTS_LIMIT = 100;
const DEFAULT_STUDENTS_LIMIT = 100;

type TAddRecurrenceFormProperties = {
  formId: string;
  groupAction: TGroupActionDetails;
  onSuccess?: () => void;
  onLoadingChange?: (isLoading: boolean) => void;
};

export const AddRecurrenceForm: React.FC<TAddRecurrenceFormProperties> = (props) => {
  const { formId, groupAction, onSuccess, onLoadingChange } = props;

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { mutate: createRecurrence } = useCreateRecurrence();
  const { data: linkedStudents } = useActionLinkedStudents({
    groupActionId: groupAction.id,
    limit: DEFAULT_LINKED_STUDENTS_LIMIT,
    offset: 0,
  });
  const { data: studentList, isFetching } = useActionLinkedStudents({
    groupActionId: groupAction.id,
    search: debouncedSearchValue,
    limit: DEFAULT_STUDENTS_LIMIT,
    offset: 0,
  });

  const memberOptions = useMemo(
    () =>
      studentList?.results.map((student) => ({
        label: student.full_name,
        value: student.id,
        avatar: student.photo,
      })) ?? [],
    [studentList],
  );

  const defaultValues = useMemo(
    () => ({
      date: new Date(),
      duration: undefined,
      members: linkedStudents?.results.map((student) => student.id) ?? [],
      myNotes: '',
      sharedNotes: '',
      title: groupAction.details.title,
    }),
    [groupAction.details.title, linkedStudents?.results],
  );

  const form = useForm<z.infer<typeof addRecurrenceSchema>>({
    resolver: zodResolver(addRecurrenceSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  const submitRecurrenceHandler = (data: z.infer<typeof addRecurrenceSchema>) => {
    onLoadingChange?.(true);
    createRecurrence(
      {
        id: groupAction.id,
        title: data.title,
        students: data.members,
        date_and_time: data.date.toISOString(),
        duration: data.duration,
        ...(data.sharedNotes && {
          shared_note: {
            text: convertStringToEditorJson(data.sharedNotes),
          },
        }),
        ...(data.myNotes && {
          private_note: {
            text: convertStringToEditorJson(data.myNotes),
          },
        }),
      },
      {
        onSuccess: () => {
          onSuccess?.();
          form.reset(defaultValues);
        },
        onSettled: () => {
          onLoadingChange?.(false);
        },
      },
    );
  };

  return (
    <Form
      providerProps={form}
      id={formId}
      className="grid w-full grid-cols-2 gap-4"
      onSubmit={form.handleSubmit(submitRecurrenceHandler)}
    >
      <FormField
        control={form.control}
        name="title"
        render={({ field }) => (
          <FormItem>
            <FormLabel required>Title</FormLabel>
            <FormControl>
              <Input
                isError={!!form.formState.errors.title}
                type="text"
                readOnly
                value={field.value}
                className="hover:border-grey-300 focus:border-grey-300 focus-visible:border-grey-300 active:border-grey-300"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="members"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Group Members</FormLabel>
            <FormControl>
              <MultiSelect
                {...field}
                isError={!!fieldState.error}
                selectedOptions={memberOptions.filter((item) => field.value.includes(item.value))}
                modalPopover
                placeholder="Select members"
                showSearch
                shouldFilter={false}
                loading={isFetching}
                onSearchChange={debouncedSearch}
                onOptionChange={field.onChange}
              >
                {memberOptions.map((option) => (
                  <MultiSelectItem
                    key={option.value}
                    value={option.value}
                    option={option}
                    isSelected={field.value.includes(option.value)}
                    customContent
                  >
                    <>
                      {field.value.includes(option.value) && (
                        <PurpleIcon
                          name="check"
                          className="absolute left-4 top-1/2 size-4 -translate-y-1/2 text-brand-blue-700"
                        />
                      )}
                      <div className="flex items-center gap-2">
                        <Avatar size={32}>
                          <AvatarImage src={option.avatar ?? undefined} alt={option.label} />
                          <AvatarFallback className="bg-grey-200">
                            {getInitialsFromName(option.label ?? '- -')}
                          </AvatarFallback>
                        </Avatar>
                        <div className="flex flex-col gap-1">
                          <strong className="line-clamp-1 text-xs font-medium text-grey-950">{option.label}</strong>
                        </div>
                      </div>
                    </>
                  </MultiSelectItem>
                ))}
              </MultiSelect>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="date"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Date & Time</FormLabel>
            <FormControl>
              <DatePicker
                mode="single"
                placeholder="Select date & time"
                formatterString="PPP p"
                isError={!!fieldState.error}
                triggerDisabled={field.disabled}
                defaultMonth={field.value}
                selected={field.value}
                disabled={{ before: new Date() }}
                captionLayout="dropdown"
                onDayClick={field.onChange}
                onTimeChange={field.onChange}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="duration"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Duration (In Minutes)</FormLabel>
            <FormControl>
              <NumberInput
                {...field}
                type="number"
                isError={!!fieldState.error}
                placeholder="Enter duration"
                min={0}
                changeOnWheel
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="sharedNotes"
        render={({ field }) => (
          <FormItem className="col-span-2">
            <FormLabel>Shared Notes</FormLabel>
            <FormControl>
              <Textarea
                {...field}
                isError={!!form.formState.errors.sharedNotes}
                placeholder="Enter notes for all members"
                responsiveHeight
                className="min-h-[80px] resize-none"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="myNotes"
        render={({ field }) => (
          <FormItem className="col-span-2">
            <FormLabel>My Notes</FormLabel>
            <FormControl>
              <Textarea
                {...field}
                isError={!!form.formState.errors.myNotes}
                placeholder="Enter your notes here"
                responsiveHeight
                className="min-h-[80px] resize-none"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </Form>
  );
};
