import { PurpleIcon } from '@purple/icons';
import { DashboardTab } from './Dashboard';
import { EcoSystemHubTab } from './EcosystemHub';
import type { TAppTab } from '@purple/ui';

export const homeTabsComponents: TAppTab[] = [
  {
    label: 'Dashboard',
    value: 'dashboard',
    content: <DashboardTab />,
    icon: <PurpleIcon name="view-grid" className="size-4 shrink-0" />,
    permissions: [],
  },
  {
    label: 'Ecosystem Hub',
    value: 'ecosystem-hub',
    content: <EcoSystemHubTab />,
    icon: <PurpleIcon name="calendar" className="size-4 shrink-0" />,
    permissions: [],
  },
];
