import { PurpleIcon } from '@purple/icons';
import { getInitialsFromName } from '@purple/shared-utils';
import { Avatar, AvatarFallback, AvatarImage, Button } from '@purple/ui';
// logo
import { Link } from 'react-router-dom';
import defaultLogo from '~/assets/images/default-logo.png';
import { CallToActionModal } from '~/components';
import { MySchoolsModal } from '~/components/Modals/MySchoolsModal';
import { ModalType } from '~/constants/modals';
// components
import { AppRoutes } from '~/constants/routes/routes';
import { useLogout } from '~/hooks';
import { SiteNavigation, UserDropdown } from '../Navigation';
// types
import type React from 'react';

const DEFAULT_NAME = 'ProUnitas';

type TMainHeaderProperties = {
  /**
   * Source of the district logo image.
   * If not provided, the default logo will be used.
   */
  logo?: string | null;
  /**
   * The name of current district.
   * If not provided, the default name will be used.
   */
  districtName?: string | null;
};

export const MainHeader: React.FC<TMainHeaderProperties> = (props) => {
  const { logo, districtName } = props;

  const { logout, isPending } = useLogout();

  const signOutHandler = () => {
    logout();
  };

  return (
    <>
      <header className="flex min-h-[68px] w-full items-center justify-between gap-4 border-b border-b-grey-300 bg-white px-8 py-4">
        <div className="flex items-center gap-8">
          <Link
            to={AppRoutes.App.Home.Root.path}
            className="flex select-none items-center gap-2 font-primary text-sm font-semibold text-brand-blue-700 outline-none"
          >
            <Avatar size={32} variant="circle">
              <AvatarImage src={logo ?? defaultLogo} alt={districtName ?? DEFAULT_NAME} />
              <AvatarFallback>{getInitialsFromName(districtName ?? DEFAULT_NAME)}</AvatarFallback>
            </Avatar>
            {districtName ?? DEFAULT_NAME}
          </Link>
          <SiteNavigation />
        </div>

        <div className="flex items-center gap-4">
          {/* TODO: Search bar goes here when time comes :) */}
          {/* TODO: Implement notification with popover and BE logic */}
          <Button
            variant="tertiary"
            size="icon_32"
            type="button"
            aria-label="Notifications"
            className="size-9"
            iconLeft={<PurpleIcon name="bell" className="size-5 shrink-0" />}
          />
          <UserDropdown />
        </div>
      </header>
      <MySchoolsModal />
      <CallToActionModal
        modalName={ModalType.LOG_OUT}
        modalTitle="Log Out?"
        modalDescription="Logout from the system modal window"
        modalTextContent="Are you sure you want to log out?"
        primaryButtonText="Yes, Log Out"
        secondaryButtonText="Cancel"
        primaryButtonVariant="destructive_primary"
        onPrimaryButtonClick={signOutHandler}
        isLoading={isPending}
      />
    </>
  );
};
