import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import {
  AccessDenied,
  Card,
  CardContent,
  CardData,
  CardDataDescription,
  CardDataIndicator,
  CardDataValue,
  CardTitle,
  ChartCardContainer,
  ChartCardContent,
  ChartCardFooter,
  ChartCardHeader,
  createLineChartData,
  Heading,
  LineChart,
  ReportCard,
} from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { useSubPageView } from '~/hooks';
import { useStudentDashboard } from '~/queries/student-dashboard';
import { DASHBOARD_VIEW } from './constants';
import DashboardSkeleton from './DashboardSkeleton';
import CircleOfSupport from './modules/CircleOfSupport';
import type { TCardDataFormatterContext } from '@purple/ui';

const FIELDS = [
  {
    name: 'Actions',
    color: '#4554A5',
    yAxisLabel: 'Action, qty',
  },
  {
    name: 'Attendance',
    color: '#F89B3F',
    yAxisLabel: 'Attendance, %',
  },
];

const Dashboard = () => {
  const { studentId } = useParams();
  const { isLoading, data } = useStudentDashboard();
  const { avgAttendancePerCycle, studentIs } = data || {};
  const navigate = useNavigate();

  const { changeView } = useSubPageView({
    defaultView: DASHBOARD_VIEW.DASHBOARD,
  });

  const formatAttendanceDescription = ({ isIncrease, isDecrease }: TCardDataFormatterContext): string => {
    const { prevRate } = avgAttendancePerCycle || {};
    if (isIncrease) {
      return `up from ${prevRate} last year`;
    }
    if (isDecrease) {
      return `down from ${prevRate} last year`;
    }
    return 'stable compared to last year';
  };

  const formatStudentIsDescription = ({ isIncrease, isDecrease }: TCardDataFormatterContext): string => {
    const { prevRate } = studentIs || {};

    if (isIncrease) {
      return `up from ${prevRate} last cycle`;
    }
    if (isDecrease) {
      return `down from ${prevRate} last cycle`;
    }
    return 'stable compared to last cycle';
  };

  const studentIsTitle = useMemo(() => {
    if (studentIs) {
      const { currentRate, prevRate } = studentIs || {};

      if (currentRate < prevRate) {
        return 'Student is - Trending Green';
      }
      if (currentRate > prevRate) {
        return 'Student is - Trending Red';
      }
      return 'Student is - Trending Yellow';
    }
    return '';
  }, [studentIs]);

  const navigateToAttendanceImpactAnalysis = () => {
    navigate(AppRoutes.App.Students.StudentDetails.AttendanceImpactAnalysis.makePath(studentId as string));
  };

  if (isLoading) return <DashboardSkeleton />;

  return (
    <div className="flex flex-col gap-4 px-4 pb-4 pt-6">
      <div className="flex flex-col gap-4">
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          Quick Insights
        </Heading>
        <div className="grid grid-cols-2 gap-4">
          {Boolean(avgAttendancePerCycle) && (
            <Guard
              requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_COLOR_TRENDS_STATISTICS]}
              fallback={(
                <AccessDenied
                  iconSize={24}
                  accessDeniedMessage="You do not have permission to view this statistic."
                  className="size-full rounded-lg border border-grey-200 bg-white p-6 py-2"
                />
              )}
            >
              <Card tag="li">
                <CardTitle>AVG Attendance Flags per Cycle this Year</CardTitle>
                <CardContent>
                  <CardData
                    value={avgAttendancePerCycle?.currentRate as number}
                    compareValue={avgAttendancePerCycle?.prevRate as number}
                    reversed
                  >
                    <CardDataValue applyTrendingColor enableAnimation formatterOptions={{ maximumFractionDigits: 2 }} />
                    <CardDataIndicator />
                    <CardDataDescription formatter={formatAttendanceDescription} />
                  </CardData>
                </CardContent>
              </Card>
            </Guard>
          )}
          {Boolean(studentIs) && (
            <Guard
              requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_COLOR_TRENDS_STATISTICS]}
              fallback={(
                <AccessDenied
                  iconSize={24}
                  accessDeniedMessage="You do not have permission to view this statistic."
                  className="size-full rounded-lg border border-grey-200 bg-white p-6 py-2"
                />
              )}
            >
              <Card tag="li">
                <CardTitle>{studentIsTitle}</CardTitle>
                <CardContent>
                  <CardData
                    value={studentIs?.currentRate as number}
                    compareValue={studentIs?.prevRate as number}
                    reversed
                  >
                    <CardDataValue applyTrendingColor enableAnimation formatterOptions={{ maximumFractionDigits: 2 }} />
                    <CardDataIndicator />
                    <CardDataDescription formatter={formatStudentIsDescription} />
                  </CardData>
                </CardContent>
              </Card>
            </Guard>
          )}
        </div>
        <div className="grid grid-cols-3 gap-4">
          <ReportCard
            title="Total SAFs"
            rate={data?.totalSAFs}
            onReportClick={() => changeView(DASHBOARD_VIEW.TOTAL_SAFS)}
          />
          <ReportCard
            title="Total Actions"
            rate={data?.totalActions}
            onReportClick={() => changeView(DASHBOARD_VIEW.TOTAL_ACTIONS)}
          />
          <ReportCard
            title="Meeting Attendance"
            rate={data?.meetingAttendance}
            onReportClick={() => changeView(DASHBOARD_VIEW.MEETING_ATTENDANCE)}
          />
        </div>
      </div>

      <ChartCardContainer>
        <ChartCardHeader onClick={() => {}} title="Attendance Impact Analysis" />
        <ChartCardContent className="p-0">
          {data && (
            <LineChart
              isDoubleYAxis
              fields={FIELDS}
              chartData={createLineChartData('Actions', 'Attendance', data?.actions, data?.attendance)}
            />
          )}
        </ChartCardContent>
        <ChartCardFooter onClick={navigateToAttendanceImpactAnalysis} actionTitle="View Report" />
      </ChartCardContainer>

      <CircleOfSupport data={data || null} />
    </div>
  );
};

export { Dashboard };
