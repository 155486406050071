import { useEffect, useMemo, useState } from 'react';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { format } from 'date-fns';
import { PurpleIcon } from '@purple/icons';
import { usePermissions } from '@purple/permissions';
import { TakeActionType } from '@purple/shared-types';
import {
  Button,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Label,
  ScrollArea,
  Separator,
} from '@purple/ui';
// hooks
import { useModal } from '~/hooks';
import { useCurrentUser } from '~/hooks/redux';
import { useActionPurpleUserParticipants, useUpdateNote } from '~/queries';
// helpers
import { ActionTypeToPermission, ReadableTakeActionType } from '~/constants/take-action';
import { showErrorToast } from '~/shared/lib';
import type { JSONContent } from '@tiptap/react';
// types
import type React from 'react';
import type { TActionDetails, TNote } from '@purple/shared-types';

const DEFAULT_MEMBERS_LIMIT = 100;

type TNotePreviewDialogProperties = {
  note: TNote | null;
  action: TActionDetails;
};

export const NotePreviewDialog: React.FC<TNotePreviewDialogProperties> = (props) => {
  const { note, action } = props;

  const [isEditable, setIsEditable] = useState<boolean>(false);

  const { isOpen, toggleModal } = useModal('action-note');
  const { mutate: updateNotes, isPending } = useUpdateNote();
  const { data: participantsData } = useActionPurpleUserParticipants(
    action.id,
    {
      limit: DEFAULT_MEMBERS_LIMIT,
    },
    { enabled: action.record_action_type === TakeActionType.STAKEHOLDER_COLLABORATIVE_MEETING },
  );

  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
      }),
      Placeholder.configure({
        placeholder: () => 'Enter a note here',
      }),
    ],
    editorProps: {
      attributes: {
        id: action.id,
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: note ? (JSON.parse(note.text) as JSONContent) : '',
    editable: isEditable,
    onCreate: ({ editor }) => {
      if (note?.text) {
        editor.commands.setContent(JSON.parse(note.text) as string);
      }
    },
  });

  const { user } = useCurrentUser();
  const { hasPermissions } = usePermissions();
  const allowedToEdit = useMemo(
    () =>
      hasPermissions(ActionTypeToPermission[action.record_action_type])
      && (user.id === action.details.created_by?.id
        || participantsData?.results.some((participant) => participant.id === user.id)),
    [user, action.details.created_by?.id, hasPermissions, action.record_action_type, participantsData?.results],
  );
  const noteLabel = useMemo(() => (note?.is_private ? 'My Notes' : 'Shared Notes'), [note?.is_private]);

  useEffect(() => {
    if (note) {
      editorConfig?.commands.setContent(JSON.parse(note.text) as string);
    }
  }, [note?.text]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeNoteDialog = () => {
    toggleModal(false);
    setIsEditable(false);
    editorConfig?.setEditable(false);
    if (note) {
      editorConfig?.commands.setContent(JSON.parse(note.text) as string);
    }
  };

  const dialogOpenChangeHandler = (open: boolean) => {
    if (open) {
      toggleModal(true);
    } else {
      closeNoteDialog();
    }
  };

  const saveNoteClickHandler = () => {
    if (note === null) {
      showErrorToast('Note error', 'The note you are trying to save is not found.');
      return;
    }
    updateNotes(
      {
        noteId: note.id,
        noteData: {
          title: note.title,
          is_private: note.is_private,
          viewers: note.viewers,
          text: JSON.stringify(editorConfig?.getJSON()),
        },
      },
      {
        onSuccess: (noteData) => {
          toggleModal(false);
          setIsEditable(false);
          editorConfig?.setEditable(false);
          editorConfig?.commands.setContent(JSON.parse(noteData.text) as string);
        },
      },
    );
  };

  const editNoteClickHandler = () => {
    editorConfig?.setEditable(true);
    setIsEditable(true);
  };

  if (!note) return null;

  return (
    <Dialog open={isOpen} onOpenChange={dialogOpenChangeHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-full max-w-[564px] flex-col">
        <DialogHeader className="flex-row items-start justify-between gap-2">
          <div className="flex w-full flex-col gap-1">
            <DialogTitle className="text-left text-lg font-semibold text-grey-title">
              [
              {noteLabel}
              ]
              {' '}
              {ReadableTakeActionType[action.record_action_type]}
              {' '}
              {format(new Date(note.updated_at), 'MM/dd/yyyy')}
            </DialogTitle>
            <DialogDescription className="text-left text-sm text-grey-600">
              {note.created_by?.full_name ?? 'N/A'}
              {' • '}
              {format(new Date(note.updated_at), 'MMMM d, yyyy, h:mm a')}
            </DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea
          type="auto"
          className="flex size-full max-h-[640px] min-h-[160px] flex-col px-6 py-4"
          scrollBarClassName="p-2 w-[22px]"
        >
          <div className="flex w-full flex-col gap-1">
            {isEditable && (
              <Label htmlFor={action.id} className="text-sm text-grey-950">
                {noteLabel}
              </Label>
            )}
            <EditorContent
              editor={editorConfig}
              className={cn(
                'minimal-tiptap-editor flex h-full min-h-[104px] w-full cursor-text flex-col rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 transition-colors duration-200 focus-within:border-brand-blue-700 hover:border-brand-blue-700 focus:border-brand-blue-700',
                {
                  'cursor-default border-none p-0': !isEditable,
                },
              )}
            />
          </div>
        </ScrollArea>
        {allowedToEdit && (
          <>
            <Separator />
            <DialogFooter>
              <Button type="button" variant="tertiary" onClick={closeNoteDialog}>
                Cancel
              </Button>
              <Button
                type="button"
                variant="primary"
                isLoading={isPending}
                disabled={isPending}
                onClick={isEditable ? saveNoteClickHandler : editNoteClickHandler}
              >
                {isEditable ? 'Save' : 'Edit'}
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
