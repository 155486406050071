import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { usePermissions } from '@purple/permissions';
import { Button, Heading } from '@purple/ui';
import { ModalType } from '~/constants/modals';
// hooks
import { useModal } from '~/hooks';
import { useCurrentUser } from '~/hooks/redux';
// constants
import { ActionTypeToPermission, ReadableTakeActionType } from '~/constants/take-action';
// components
import { ChoiceTag, GroupActionAddRecurrenceDialog } from '~/components';
import { DeleteGroupActionDialog } from './DeleteGroupActionDialog';
import { GroupActionAddMemberDialog } from './GroupActionAddMemberDialog';
// types
import type React from 'react';
import type { TGroupActionDetails } from '@purple/shared-types';

type TGroupActionHeaderProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupActionHeader: React.FC<TGroupActionHeaderProperties> = (props) => {
  const { groupAction } = props;

  const { openModal } = useModal(ModalType.DELETE_GROUP_ACTION);
  const { openModal: openManageMembersModal } = useModal(ModalType.GROUP_ACTION_MANAGE_MEMBERS);
  const { openModal: openRecurrenceModal } = useModal(ModalType.GROUP_ACTION_ADD_RECURRENCE);
  const { user } = useCurrentUser();
  const { hasPermissions } = usePermissions();
  const allowedToDelete = useMemo(
    () =>
      user.id === groupAction.details.created_by?.id
      && hasPermissions(ActionTypeToPermission[groupAction.record_action_type]),
    [user, groupAction, hasPermissions],
  );

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-4">
          <div className="flex flex-col gap-2">
            <Heading tag="h1" className="text-lg font-semibold text-grey-title">
              (
              {groupAction.details.name}
              )
              {' '}
              {groupAction.details.title}
            </Heading>
            <ul className="flex flex-wrap items-center gap-2">
              <li>
                <ChoiceTag>{ReadableTakeActionType[groupAction.record_action_type]}</ChoiceTag>
              </li>
              {groupAction.tags.map(({ color, name }) => (
                <li key={name}>
                  <ChoiceTag color={color}>{name}</ChoiceTag>
                </li>
              ))}
            </ul>
          </div>
          {allowedToDelete && (
            <Button
              type="button"
              aria-label="Delete action"
              variant="destructive_secondary"
              size="icon_32"
              className="border-0"
              iconLeft={<PurpleIcon name="trash" className="size-5 shrink-0" />}
              onClick={openModal}
            />
          )}
        </div>
        <div className="flex gap-4">
          <Button type="button" variant="primary" onClick={openManageMembersModal}>
            Manage Members
          </Button>
          <Button type="button" variant="secondary" onClick={openRecurrenceModal}>
            Add Recurrence
          </Button>
        </div>
      </div>
      <DeleteGroupActionDialog groupAction={groupAction} />
      <GroupActionAddMemberDialog groupAction={groupAction} />
      <GroupActionAddRecurrenceDialog groupAction={groupAction} />
    </>
  );
};
