import { z } from 'zod';

export const campusProposalActivitySchema = z.object({
  school: z
    .string({ invalid_type_error: 'School must be a string.' })
    .trim()
    .min(1, { message: 'School is required.' }),
  tags: z.array(z.string()),
  name: z
    .string({ invalid_type_error: 'Activity Name must be a string.' })
    .trim()
    .min(1, { message: 'Activity Name is required.' }),
  type: z.string({ invalid_type_error: 'Type must be a string.' }).trim().min(1, { message: 'Type is required.' }),
  date_and_time: z.coerce
    .date({ required_error: 'Date is required.', message: 'Date is invalid.' })
    .refine((date) => date > new Date(), { message: 'Date must be in the future' }),
  duration: z
    .number({ message: 'Duration must be an integer.' })
    .int()
    .gt(0, { message: 'Duration must be greater than 0.' })
    .max(720, { message: 'Duration must be less than 720 minutes.' }),
  description: z.string({ invalid_type_error: 'Description must be a string.' })
    .trim()
    .min(1, { message: 'Description is required.' }),
});
