const GRADE_PREFIX = 'Grade ';

export const cutGradeName = (grade: string) => {
  return grade.replaceAll(GRADE_PREFIX, '');
};

export const cutGradesNames = (grades: string[]) => {
  return grades.map((grade) => cutGradeName(grade));
};

export const getGradeLabel = (grades?: string[]): string => {
  if (!grades || grades.length === 0) {
    return '-';
  }

  const sortedGrades = [...grades]
    .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }));
  const [gradeLevels, otherGrades] = sortedGrades.reduce(
    (acc, grade) => {
      if (grade.includes(GRADE_PREFIX)) {
        acc[0].push(cutGradeName(grade));
      } else {
        acc[1].push(grade);
      }

      return acc;
    },
    [[], []] as [string[], string[]],
  );
  const [firstGrade] = otherGrades;
  const lastGrade = gradeLevels.pop();

  if (firstGrade && lastGrade) {
    return `${firstGrade} - ${lastGrade}`;
  }

  if (firstGrade) {
    return firstGrade;
  }

  if (lastGrade) {
    return lastGrade;
  }

  return '-';
};

export const getGradeGroups = (grades?: string[]): string => {
  if (!grades || grades.length === 0) {
    return '-';
  }

  const [gradeLevels, otherGrades] = grades.reduce(
    (acc, grade) => {
      if (grade.includes(GRADE_PREFIX)) {
        acc[0].push(grade);
      } else {
        acc[1].push(grade);
      }

      return acc;
    },
    [[], []] as [string[], string[]],
  );

  const levels = [...gradeLevels]
    .map((grade) => cutGradeName(grade))
    .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
    .reduce((acc, grade, index) => index === 0 ? `${acc}${grade}` : `${acc}, ${grade}`, 'Grade(s): ');

  const other = otherGrades.join('\n');

  return `${other ? `${other}\n` : ''}${gradeLevels.length > 0 ? `${levels}` : ''}`;
};
