import { useNavigate, useParams } from 'react-router-dom';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { AccessDenied } from '@purple/ui';
import { LinkButton } from '~/components/LinkButton';
import { AppRoutes } from '~/constants/routes/routes';
import { useGoBack } from '~/hooks';
import { ActionDetails } from '~/modules/Actions';
import { useActionDetails } from '~/queries';

export const ActionDetailsPage: React.FC = () => {
  const { actionId } = useParams();
  const navigate = useNavigate();

  const { data: action } = useActionDetails(actionId as string);
  const { onGoBack } = useGoBack({
    fallbackPath: action?.details?.school?.id
      ? AppRoutes.App.School.Root.makePath(action.details.school.id, 'actions')
      : null,
  });

  if (!actionId) {
    navigate(AppRoutes.System.NotFound.Root.path, { replace: true });
  }

  return (
    <div className="flex w-full flex-col gap-6">
      <LinkButton to={AppRoutes.App.Home.Root.path} iconName="chevron-left" onClick={onGoBack}>
        Back to Total Actions
      </LinkButton>
      <Guard
        requiredPermissions={[
          APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
          APP_PERMISSIONS.CAN_ACCESS_TAKE_ACTION_WIZARD,
        ]}
        fallback={(
          <AccessDenied
            accessDeniedMessage="You do not have permission to view action details."
            className="size-full rounded-lg border border-grey-200 bg-white p-6 py-24"
          />
        )}
      >
        <ActionDetails />
      </Guard>
    </div>
  );
};
