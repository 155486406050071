import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch, useTimePeriod } from '@purple/hooks';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { AppSelectedFiltersList, Heading, TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { TIME_PERIODS } from '~/constants/options';
import { useSchoolActionsList } from '~/queries/schools/actions';
import { ActionsHeaderSection } from './ActionsHeaderSection';
import { SCHOOL_ACTION_FILTERS_OPTIONS } from './constants';
import { schoolActionsColumns } from './schoolActionsColumns';

export const Actions: React.FC = () => {
  const { schoolId } = useParams();
  const [searchParameters] = useSearchParams();

  const { debounceSearch, search } = useSearch();

  const sort = searchParameters.get(SORT_QUERY_NAME) || '';

  const { onPeriodChange, onPeriodClear, timePeriod } = useTimePeriod({
    defaultValue: TIME_PERIODS.this_academic_year,
  });
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const { data, isLoading } = useSchoolActionsList({
    schoolId: schoolId as string,
    queryParameters: {
      limit,
      offset,
      ordering: sort,
      search: debounceSearch,
      document_as: searchParameters.get('document_as') || undefined,
      time_period: timePeriod,
    },
  });

  const isDataNotExist = useMemo(
    () => !data?.results || (data.results.length === 0 && !isLoading),
    [data?.results, isLoading],
  );

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  if (!schoolId) {
    return null;
  }

  return (
    <>
      <div className="flex px-4 pb-4 pt-6">
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          Actions
        </Heading>
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex flex-row px-4">
          <ActionsHeaderSection
            actionsAmount={data?.totals}
            isLoading={isLoading}
            timePeriod={timePeriod}
            onPeriodChange={onPeriodChange}
            onPeriodClear={onPeriodClear}
          />
        </div>
        <AppSelectedFiltersList config={SCHOOL_ACTION_FILTERS_OPTIONS} maxDisplayedFilters={8} className="px-4" />
        <div className="flex flex-col gap-5">
          <div>
            <DataTable
              columns={schoolActionsColumns}
              data={data?.results || []}
              isLoading={isLoading}
              isFiltersApplied={Boolean(search)}
              emptyStateTitle="No Actions found"
              emptyStateMessage="There are no Actions to display. If it an error, please contact support."
              skeleton={<DataTableSkeleton rows={limit} />}
            />
            {!isDataNotExist && (
              <TableFooterSection
                currentPage={page}
                pageCount={pageCount}
                onPageChange={onPageChange}
                rowsPerPage={limit}
                onRowsPerPageChange={onLimitChange}
                totalRows={data?.count || 0}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
