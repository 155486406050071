import { useMemo } from 'react';
import { isFieldExist } from '@purple/shared-utils';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, AppTabs } from '@purple/ui';
// hooks
import { useTabs } from '@purple/hooks';
import { GroupActionDetailsSection } from './GroupActionDetailsSection';
import { GroupActionGoalsSection } from './GroupActionGoalsSection';
// components
import { GroupActionHeader } from './GroupActionHeader';
import { GroupActionHistory } from './GroupActionHistory';
import { GroupActionLinkedStudents } from './GroupActionLinkedStudents';
import { GroupActionNotes } from './GroupActionNotes';
import { GroupActionTagsSection } from './GroupActionTagsSection';
import { GroupCrisisDetailsSection } from './GroupCrisisDetailsSection';
import { GroupCrisisFieldsSection } from './GroupCrisisFieldsSection';
import { GroupDateAndTimeSection } from './GroupDateAndTimeSection';
import { GroupScreenersAndResultsSection } from './GroupScreenersAndResultsSection';
// types
import type React from 'react';
import type { TGroupActionDetails } from '@purple/shared-types';
import type { TAppTab } from '@purple/ui';

type TGroupActionContainerProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupActionContainer: React.FC<TGroupActionContainerProperties> = (props) => {
  const { groupAction } = props;

  const groupActionTabs: TAppTab[] = useMemo(
    () => [
      {
        label: 'Notes',
        value: 'notes',
        content: <GroupActionNotes groupAction={groupAction} />,
        permissions: [],
      },
      {
        label: 'Linked Students',
        value: 'linked-students',
        content: <GroupActionLinkedStudents groupAction={groupAction} />,
        permissions: [],
      },
      {
        label: 'History/Timeline',
        value: 'history-timeline',
        content: <GroupActionHistory groupAction={groupAction} />,
        permissions: [],
      },
    ],
    [groupAction],
  );

  const { activeTab, tabChangeHandler } = useTabs({
    tabs: groupActionTabs,
  });

  if (!activeTab) return null;

  return (
    <div className="grid w-full grid-cols-1 items-start gap-y-6 xl:grid-cols-3 xl:gap-6">
      <aside className="flex w-full shrink-0 flex-col gap-6 rounded-lg border border-grey-200 bg-white px-4 pt-6 shadow-custom-heavy">
        <GroupActionHeader groupAction={groupAction} />
        <GroupActionGoalsSection groupAction={groupAction} />
        <GroupCrisisFieldsSection groupAction={groupAction} />
        <Accordion type="multiple" defaultValue={['details']}>
          <AccordionItem value="details" className="border-t border-grey-200">
            <AccordionTrigger>Details</AccordionTrigger>
            <AccordionContent>
              <GroupActionDetailsSection groupAction={groupAction} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="date-and-time" className="border-grey-200">
            <AccordionTrigger>Date & Time Details</AccordionTrigger>
            <AccordionContent>
              <GroupDateAndTimeSection groupAction={groupAction} />
            </AccordionContent>
          </AccordionItem>
          {isFieldExist(groupAction.screeners_and_results) && (
            <AccordionItem value="screeners-and-results" className="border-grey-200">
              <AccordionTrigger>Screeners and Results</AccordionTrigger>
              <AccordionContent>
                <GroupScreenersAndResultsSection groupAction={groupAction} />
              </AccordionContent>
            </AccordionItem>
          )}
          {isFieldExist(groupAction.crisis_details) && (
            <AccordionItem value="crisis-details" className="border-grey-200">
              <AccordionTrigger>Crisis Details</AccordionTrigger>
              <AccordionContent>
                <GroupCrisisDetailsSection groupAction={groupAction} />
              </AccordionContent>
            </AccordionItem>
          )}
          <AccordionItem value="tags" className="border-none">
            <AccordionTrigger>Tags</AccordionTrigger>
            <AccordionContent>
              <GroupActionTagsSection groupAction={groupAction} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </aside>
      <AppTabs
        tabs={groupActionTabs}
        className="col-span-2 flex flex-col items-start xl:mt-[-30px]"
        listClassName="pl-2"
        value={activeTab}
        onValueChange={tabChangeHandler}
      />
    </div>
  );
};
