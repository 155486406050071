import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { Button, Text } from '@purple/ui';
import { ModalType } from '~/constants/modals';
// hooks
import { useModal } from '~/hooks';
import { useFileById, useUpdateFile, useUploadFile } from '~/queries';
// helpers
import { showErrorToast } from '~/shared/lib';
// components
import { AccessSettingsDialog } from '../AccessSettingsDialog';
// types
import type React from 'react';

type TUploadFilePreviewHeaderProperties = {
  uploadedFile: File | null;
  selectedFileId: string | null;
  setUploadedFile: (file: File | null) => void;
  setSelectedFileId: (fileId: string | null) => void;
};

export const UploadFilePreviewHeader: React.FC<TUploadFilePreviewHeaderProperties> = (props) => {
  const { uploadedFile, selectedFileId, setUploadedFile, setSelectedFileId } = props;

  const { schoolId } = useParams();
  const { isOpen, toggleModal } = useModal(ModalType.UPLOAD_FILE_PREVIEW);
  const { openModal } = useModal(ModalType.FILES_ACCESS_SETTINGS);

  const { data } = useFileById(selectedFileId as string);
  const { mutate: uploadFile, isPending: isUploading } = useUploadFile();
  const { mutate: updateFile, isPending: isUpdating } = useUpdateFile();

  const isFormSubmitting = useMemo(() => isUploading || isUpdating, [isUploading, isUpdating]);

  const closePreviewModal = () => {
    setUploadedFile(null);
    setSelectedFileId(null);
    toggleModal(!isOpen);
  };

  const approveFileUploadHandler = () => {
    if (!uploadedFile || !schoolId) {
      showErrorToast('System Message', 'Unexpected error occurred. Please try again.');
      return;
    }
    uploadFile(
      {
        content_type: 'school',
        object_id: schoolId,
        title: uploadedFile.name,
        file: uploadedFile,
        is_private: false,
        viewers: [],
      },
      {
        onSuccess: closePreviewModal,
      },
    );
  };

  const approvedFileUpdateHandler = () => {
    if (!uploadedFile || !selectedFileId) {
      showErrorToast('System Message', 'Unexpected error occurred. Please try again.');
      return;
    }
    updateFile(
      {
        title: uploadedFile.name,
        file: uploadedFile,
        is_private: data?.is_private || false,
        viewers: data?.viewers.map(({ id }) => id) || [],
        fileId: selectedFileId,
      },
      {
        onSuccess: closePreviewModal,
      },
    );
  };

  const submitHandler = selectedFileId ? approvedFileUpdateHandler : approveFileUploadHandler;

  return (
    <div className="fixed left-0 top-0 z-50 flex w-full items-center justify-between bg-white px-8 py-[18px]">
      <Button variant="link" onClick={closePreviewModal} iconLeft={<PurpleIcon name="chevron-left" />}>
        Back to School Profile
      </Button>
      <div className="flex items-center gap-2">
        <Text variant="size-14" type="body-500" className="max-w-[350px] truncate text-grey-title">
          {uploadedFile?.name || 'Untitled'}
        </Text>
        <Button
          variant="link"
          className="p-0"
          onClick={closePreviewModal}
          iconLeft={<PurpleIcon name="trash" className="size-4 shrink-0 text-error-main" />}
        />
      </div>
      <div className="flex gap-4">
        <Button
          variant="secondary"
          type="button"
          iconLeft={<PurpleIcon name="lock-closed" />}
          onClick={openModal}
          disabled={isUploading}
        >
          Access Settings
        </Button>
        <Button onClick={submitHandler} isLoading={isFormSubmitting} disabled={isFormSubmitting}>
          Continue
        </Button>
      </div>
      <AccessSettingsDialog
        uploadedFile={uploadedFile}
        selectedFileId={selectedFileId}
        setUploadedFile={setUploadedFile}
        setSelectedFileId={setSelectedFileId}
      />
    </div>
  );
};
