import { useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { cutGradeName, getInitialsFromName } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  Separator,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useAddLinkedStudents, useGetAllStudents } from '~/queries';
import { showErrorToast } from '~/shared/lib';
import { addGroupMembersSchema } from './addGroupMembersSchema';
import type * as z from 'zod';
import type { TActionLinkedStudent } from '@purple/shared-types';

const DEFAULT_STUDENTS_LIMIT = 50;

type TAddGroupActionMembersModalProperties = {
  groupActionId: string | null;
  existingMembers: TActionLinkedStudent[];
};

type TFormValues = z.infer<typeof addGroupMembersSchema>;

export const AddGroupActionMembersModal = ({
  groupActionId,
  existingMembers,
}: TAddGroupActionMembersModalProperties) => {
  const formId = useId();
  const { isOpen, closeModal, toggleModal } = useModal('add-group-action-members-modal');

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  const { data: studentList, isFetching: isStudentsFetching } = useGetAllStudents({
    search: debouncedSearchValue,
    limit: DEFAULT_STUDENTS_LIMIT,
    offset: 0,
  });

  const { mutate, isPending } = useAddLinkedStudents(groupActionId as string);

  const multiSelectOptions = useMemo(
    () =>
      studentList?.results
        .filter((user) => !existingMembers.some((member) => member.id === user.id))
        .map((user) => ({
          ...user,
          label: `${user.first_name} ${user.last_name}`,
          value: user.id,
        })) ?? [],
    [studentList, existingMembers],
  );

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(addGroupMembersSchema),
    defaultValues: {
      students: [],
    },
  });

  const addGroupActionMembersHandler = (formData: TFormValues) => {
    if (!groupActionId) {
      return showErrorToast('System Message', 'An error occurred while adding members');
    }
    mutate(
      { students: formData.students },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="max-w-[564px]">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle>Add Members</DialogTitle>
            <DialogDescription className="sr-only">Search and add members to the group action</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          providerProps={form}
          className="p-6"
          onSubmit={form.handleSubmit(addGroupActionMembersHandler)}
          id={formId}
        >
          <FormField
            control={form.control}
            name="students"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>Members List</FormLabel>
                <FormControl>
                  <MultiSelect
                    {...field}
                    isError={!!fieldState.error}
                    selectedOptions={multiSelectOptions.filter((user) => field.value.includes(user.value))}
                    showSearch
                    loading={isStudentsFetching}
                    shouldFilter={false}
                    searchPlaceholder="Search by student name or ID"
                    placeholder="Select student(s)"
                    modalPopover
                    onOptionChange={field.onChange}
                    onSearchChange={debouncedSearch}
                  >
                    {multiSelectOptions.map((option) => (
                      <MultiSelectItem
                        key={option.value}
                        value={option.value}
                        option={option}
                        isSelected={field.value.includes(option.value)}
                        customContent
                      >
                        <>
                          {field.value.includes(option.value) && (
                            <PurpleIcon
                              name="check"
                              className="absolute left-4 top-1/2 size-4 -translate-y-1/2 text-brand-blue-700"
                            />
                          )}
                          <div className="flex items-center gap-2">
                            <Avatar size={32}>
                              <AvatarImage
                                src={option.photo ?? undefined}
                                alt={`${option.first_name} ${option.last_name}`}
                              />
                              <AvatarFallback className="bg-grey-200">
                                {getInitialsFromName(`${option.first_name} ${option.last_name}`)}
                              </AvatarFallback>
                            </Avatar>
                            <div className="flex flex-col gap-1">
                              <strong className="line-clamp-1 text-xs font-medium text-grey-950">
                                {`${option.first_name} ${option.last_name}`}
                              </strong>
                              <div className="flex items-center gap-2">
                                <span className="line-clamp-1 text-xs text-grey-600">
                                  School:
                                  {option.school.name}
                                </span>
                                <span className="line-clamp-1 text-xs text-grey-600">
                                  Grade:
                                  {' '}
                                  {option.grade ? cutGradeName(option.grade) : '—'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      </MultiSelectItem>
                    ))}
                  </MultiSelect>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" disabled={isPending} form={formId} isLoading={isPending}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
