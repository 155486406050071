import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '~/hooks';
import { districtSelector } from '~/store/features/district';
import { MainContainer } from '../MainContainer';
import { FormPageHeader } from './FormPageHeader';

export const FormLayout: React.FC = () => {
  const { logo, name } = useAppSelector(districtSelector) ?? {};

  const districtName = useMemo(() => name ?? 'Unknown School District', [name]);

  return (
    <MainContainer>
      <FormPageHeader imageSrc={logo} districtName={districtName} />
      <main className="flex w-full max-w-[1920px] grow justify-center gap-4 px-8 py-[30px]">
        <Outlet />
      </main>
    </MainContainer>
  );
};
