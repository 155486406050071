import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch } from '@purple/hooks';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { Heading, TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton, StudentIncidentDetailModal } from '~/components';
import { useModal } from '~/hooks';
import { useGetStudentIncidentsList } from '~/queries';
import { FilterActionsRow } from './FilterActionsRow';
import { studentIncidentsColumns } from './studentIncidentsColumns';

const Incidents = () => {
  const { studentId } = useParams();
  const [searchParameters] = useSearchParams();

  const { debounceSearch, search } = useSearch();

  const sort = searchParameters.get(SORT_QUERY_NAME);
  const incident_type = searchParameters.get('incident_type');

  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const { isOpen } = useModal('detail-student-incident');

  const { isLoading: isDataLoading, data } = useGetStudentIncidentsList({
    studentId: studentId as string,
    requestParameters: {
      limit,
      offset,
      search: debounceSearch,
      ordering: sort,
      incident_type,
    },
  });

  const isDataNotExist = (!data?.results || data.results.length === 0) && !isDataLoading;

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  if (!studentId) {
    return null;
  }

  return (
    <div>
      <div className="flex px-4 pb-4 pt-6">
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          Incidents
        </Heading>
      </div>
      <div className="flex flex-col gap-5">
        <FilterActionsRow />
        <div>
          <DataTable
            columns={studentIncidentsColumns}
            data={data?.results || []}
            isLoading={isDataLoading}
            isFiltersApplied={Boolean(search)}
            emptyStateTitle="No incidents found"
            emptyStateMessage="There are no incidents to display. If it is an error, please contact support."
            skeleton={<DataTableSkeleton rows={limit} />}
          />
          {!isDataNotExist && (
            <TableFooterSection
              pageCount={pageCount}
              currentPage={page}
              onPageChange={onPageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={onLimitChange}
              totalRows={data?.count || 0}
            />
          )}
        </div>
      </div>
      {isOpen && <StudentIncidentDetailModal />}
    </div>
  );
};

export { Incidents };
