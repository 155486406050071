import { nativeEnum, z } from 'zod';
import { ReminderDaysOfWeek, ReminderType, ReminderWeeklyInterval } from '@purple/shared-types';

export const takeActionReminderSchema = z
  .object({
    type: nativeEnum(ReminderType),
    subject: z.string().trim().min(1, {
      message: 'Subject is required.',
    }),
    notifyBeforeStart: z
      .number({
        invalid_type_error: 'Notify before start must be a number.',
        message: 'Notify before start must be an integer.',
      })
      .int()
      .gt(0, {
        message: 'Notify before start must be greater than 0.',
      }),
    date: z.coerce
      .date({
        message: 'Date is invalid.',
        required_error: 'Date is required.',
      })
      .refine((data) => data > new Date(), { message: 'Date must be in the future' }),
    endDate: z.date().optional(),
    isAllDayEvent: z.boolean(),
    duration: z
      .number({
        invalid_type_error: 'Duration must be a number.',
        message: 'Duration must be an integer.',
      })
      .int()
      .gt(0, {
        message: 'Duration must be greater than 0.',
      })
      .max(720, { message: 'Duration must be less than 720 minutes.' })
      .optional(),
    daysOfWeek: z.array(nativeEnum(ReminderDaysOfWeek)).optional(),
    weeklyInterval: nativeEnum(ReminderWeeklyInterval).optional(),
    description: z.string().trim().optional(),
  })
  .superRefine((data, context) => {
    if (data.type === ReminderType.RECURRING && !data.endDate) {
      context.addIssue({
        path: ['endDate'],
        code: z.ZodIssueCode.custom,
        message: 'End date is required.',
      });
    }

    if (data.type === ReminderType.RECURRING && (!data.daysOfWeek || data.daysOfWeek.length === 0)) {
      context.addIssue({
        path: ['daysOfWeek'],
        code: z.ZodIssueCode.custom,
        message: 'Days of week is required.',
      });
    }

    if (data.type === ReminderType.RECURRING && !data.weeklyInterval) {
      context.addIssue({
        path: ['weeklyInterval'],
        code: z.ZodIssueCode.custom,
        message: 'Weekly interval is required.',
      });
    }

    if (data.type === ReminderType.RECURRING && data.endDate && data.endDate < data.date) {
      context.addIssue({
        path: ['endDate'],
        code: z.ZodIssueCode.custom,
        message: 'End date cannot be earlier than start date.',
      });
    }

    if (!data.isAllDayEvent && !data.duration) {
      context.addIssue({
        path: ['duration'],
        code: z.ZodIssueCode.custom,
        message: 'Duration is required for non-all day events.',
      });
    }
  });
