import { Skeleton } from '@purple/ui';

const ProfileSidebarHeaderSkeleton = () => {
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex gap-3">
        <Skeleton className="size-[60px] rounded-full" />
        <div className="flex flex-1 flex-col gap-1">
          <Skeleton className="h-[28px] w-full" />
        </div>
      </div>
      <div className="flex gap-4">
        <Skeleton className="h-[40px] w-[153px]" />
        <Skeleton className="h-[40px] w-[130px]" />
      </div>
    </div>
  );
};

export { ProfileSidebarHeaderSkeleton };
