import { useTabs } from '@purple/hooks';
import { AccessDenied, AppTabs } from '@purple/ui';
import { ActivityFiles } from '../Files';
import { ActivityHistory } from '../HistoryTimeline';
import type React from 'react';
import type { TAppTab } from '@purple/ui';

const activityDetailsTabs = [
  {
    label: 'Files',
    value: 'files',
    content: <ActivityFiles />,
    permissions: [],
  },
  {
    label: 'History/Timeline',
    value: 'history-timeline',
    content: <ActivityHistory />,
    permissions: [],
  },
] as const satisfies TAppTab[];

export const ActivityDetailsTabs: React.FC = () => {
  const { activeTab, tabChangeHandler } = useTabs({
    tabs: activityDetailsTabs,
  });

  if (!activeTab) {
    return (
      <div className="col-span-2 w-full gap-4 rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
        <AccessDenied accessDeniedMessage="You do not have permission to view this content." />
      </div>
    );
  }

  return (
    <AppTabs
      className="col-span-2 flex flex-col items-start 2xl:mt-[-30px]"
      listClassName="px-2"
      tabs={activityDetailsTabs}
      value={activeTab}
      onValueChange={tabChangeHandler}
    />
  );
};
