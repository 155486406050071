import { useMemo } from 'react';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { PurpleIcon } from '@purple/icons';
import { Text } from '@purple/ui';
import { CorrectMark } from './components/CorrectMark';
import { StudentIdColumn } from './components/StudentIdColumn';
import type { IEditionSaveStudentByIdFormEntity, IStudentByIdFormEntity } from './types';

type TUseManualFormColumns = (options: {
  onChange: (value: IEditionSaveStudentByIdFormEntity) => void;
  onRemove: (id: string) => void;
}) => ColumnDef<IStudentByIdFormEntity, string>[];

export const useManualFormColumns: TUseManualFormColumns = ({ onChange, onRemove }) => {
  const columnHelper = createColumnHelper<IStudentByIdFormEntity>();

  const columns: ColumnDef<IStudentByIdFormEntity, string>[] = useMemo(
    () => [
      columnHelper.accessor(() => 'checkmark', {
        id: 'is_correct',
        header: () => {
          return <Text variant="size-12" type="body-600" className="w-[30px] text-grey-600" />;
        },
        cell: ({ row: { original } }) => {
          const { isCorrect } = original;
          return (
            <div className="flex items-center justify-center">
              <CorrectMark isCorrect={isCorrect} />
            </div>
          );
        },
        size: 30,
      }),
      columnHelper.accessor(() => 'id', {
        id: 'id',
        header: () => {
          return (
            <Text variant="size-12" type="body-600" className="text-grey-600">
              STUDENT ID
            </Text>
          );
        },
        cell: ({ row: { original } }) => {
          return <StudentIdColumn student={original} onEdit={onChange} />;
        },
        size: 160,
      }),
      columnHelper.accessor(() => 'fullName', {
        id: 'fullName',
        header: () => {
          return (
            <Text variant="size-12" type="body-600" className="text-grey-600">
              FULL NAME
            </Text>
          );
        },
        cell: ({ row: { original } }) => {
          const { fullName } = original;
          return (
            <Text variant="size-14" type="body-500" className="text-grey-950">
              {fullName}
            </Text>
          );
        },
      }),
      columnHelper.accessor(() => 'action', {
        id: 'action',
        header: () => null,
        cell: ({ row: { original } }) => {
          return (
            <button type="button" className="w-fit" onClick={() => onRemove(original.id)}>
              <PurpleIcon name="trash" className="size-4 text-error-main" />
            </button>
          );
        },
        size: 30,
      }),
    ],
    [columnHelper, onChange, onRemove],
  );

  return columns;
};
