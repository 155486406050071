import { useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { TakeActionType } from '@purple/shared-types';
import { AccessDenied, NoDataAvailable } from '@purple/ui';
import { useActionDetails } from '~/queries';
import { ActionDetailsSkeleton } from './components';
import { IndividualActionDetails } from './IndividualActionDetails';

export const ActionDetails: React.FC = () => {
  const { actionId } = useParams();

  const { data: action, isPending } = useActionDetails(actionId as string);
  const { hasPermissions } = usePermissions();

  if (!action && !isPending) {
    return (
      <NoDataAvailable
        iconName="presentation-chart-bar"
        title="Action data not available"
        description="For some reason, the action data is not available. Please try again later."
        className="size-full rounded-lg border border-grey-200 bg-white p-6 py-24"
      />
    );
  }

  if (
    action?.record_action_type === TakeActionType.CRISIS_RESPONSE_SESSION
    && !hasPermissions(APP_PERMISSIONS.CAN_ACCESS_CRISIS_RESPONSE_SESSION_LESSON_ACTION)
  ) {
    return (
      <AccessDenied
        accessDeniedMessage="You do not have permission to view action details."
        className="size-full rounded-lg border border-grey-200 bg-white p-6 py-24"
      />
    );
  }

  return (
    <AnimatePresence mode="wait">
      {isPending
        ? (
            <motion.div
              key="skeleton"
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 30 }}
              transition={{ duration: 0.3 }}
            >
              <ActionDetailsSkeleton />
            </motion.div>
          )
        : (
            <motion.div
              key="details"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -30 }}
              transition={{ duration: 0.3 }}
            >
              <IndividualActionDetails action={action} />
            </motion.div>
          )}
    </AnimatePresence>
  );
};
