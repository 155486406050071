import { PurpleIcon } from '@purple/icons';
import { Text, Tooltip, TooltipContent, TooltipTrigger } from '@purple/ui';

export const OcDayTooltip = () => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <PurpleIcon name="information-circle" className="size-4 text-grey-600" />
      </TooltipTrigger>
      <TooltipContent className="max-w-[250px]">
        <Text variant="size-14" className="text-pretty text-white">
          Calculates total number of days between when a SAF was prioritized (when a priority level was assigned to it)
          and when it was closed.
        </Text>
      </TooltipContent>
    </Tooltip>
  );
};
