import { format } from 'date-fns';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading, Text } from '@purple/ui';
import type React from 'react';
import type { ToolbarProps } from 'react-big-calendar';

type TAttendanceCalendarToolbarProperties = ToolbarProps<{
  title: string;
  start: Date;
  end: Date;
  value: number;
}> & {
  selectedDate: Date;
};

export const AttendanceCalendarToolbar: React.FC<TAttendanceCalendarToolbarProperties> = (props) => {
  const { selectedDate, onNavigate } = props;

  const nextMonthClickHandler = () => {
    onNavigate('NEXT');
  };

  const previousMonthClickHandler = () => {
    onNavigate('PREV');
  };

  return (
    <div className="flex flex-row items-center justify-between rounded-t-lg border border-b-0 border-grey-200 p-4">
      <Heading tag="h2" variant="size-18" type="heading-600" className="text-base text-grey-950">
        Attendance Calendar
      </Heading>
      <div className="flex flex-row items-center gap-2">
        <Button
          variant="tertiary"
          size="icon_32"
          iconLeft={<PurpleIcon name="chevron-left" className="text-brand-blue-700" />}
          onClick={previousMonthClickHandler}
        />
        <Text variant="size-14" type="body-600" className="min-w-28 text-center text-brand-blue-700">
          {format(selectedDate, 'MMMM, yyyy')}
        </Text>
        <Button
          variant="tertiary"
          size="icon_32"
          iconLeft={<PurpleIcon name="chevron-right" className="text-brand-blue-700" />}
          onClick={nextMonthClickHandler}
        />
      </div>
    </div>
  );
};
