import { useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { NoDataAvailable } from '@purple/ui';
import { useActivityDetails } from '~/queries';
import { ActivityDetailsSideBar, ActivityDetailsSkeleton, ActivityDetailsTabs } from './components';

export const ActivityDetails: React.FC = () => {
  const { activityId } = useParams();

  const { data: activity, isPending } = useActivityDetails(activityId as string);

  if (!activity && !isPending) {
    return (
      <NoDataAvailable
        iconName="presentation-chart-bar"
        title="Activity data not available"
        description="For some reason, the activity data is not available. Please try again later."
        className="size-full rounded-lg border border-grey-200 bg-white p-6 py-24"
      />
    );
  }

  return (
    <AnimatePresence mode="wait">
      {isPending
        ? (
            <motion.div
              key="skeleton"
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 30 }}
              transition={{ duration: 0.3 }}
            >
              <ActivityDetailsSkeleton />
            </motion.div>
          )
        : (
            <motion.div
              key="details"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -30 }}
              transition={{ duration: 0.3 }}
              className="grid w-full grid-cols-1 items-start gap-y-6 2xl:grid-cols-3 2xl:gap-x-6 2xl:gap-y-0"
            >
              <ActivityDetailsSideBar activity={activity} />
              <ActivityDetailsTabs />
            </motion.div>
          )}
    </AnimatePresence>
  );
};
