import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { AppLoaderScreen, Button, Heading } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { useDeclineEmailMutation } from '~/queries';

const DeclineEmail = () => {
  const navigate = useNavigate();
  const { userId, token } = useParams();

  const userUID = userId || '';
  const resetToken = token || '';

  const { mutate, isSuccess, isError } = useDeclineEmailMutation();

  useEffect(() => {
    if (userUID && resetToken) {
      mutate({ uid: userUID, token: resetToken });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goHomeClickHandler = () => {
    navigate(AppRoutes.App.Home.Root.path, { replace: true });
  };

  if (isSuccess || isError) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-[20px]">
          <div className="flex items-center justify-center rounded-xl bg-success-bg p-3">
            <PurpleIcon name="checkmark-circle" className="size-8 text-success-main" />
          </div>

          <Heading variant="size-18" type="heading-500" className="text-grey-700">
            Your email change request has been canceled
          </Heading>

          <Button onClick={goHomeClickHandler}>Go to Home Page</Button>
        </div>
      </div>
    );
  }

  return <AppLoaderScreen />;
};

export { DeclineEmail };
