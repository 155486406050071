import { Separator, Skeleton } from '@purple/ui';

export const ActionDetailsSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-col gap-6 rounded-lg border border-grey-200 bg-white p-6 shadow-custom-light">
        <div className="flex w-full justify-between gap-4">
          <div className="flex flex-col gap-2">
            <Skeleton className="h-7 w-44" />
            <div className="flex flex-wrap items-center gap-2">
              <Skeleton className="h-6 w-20 rounded-full" />
              <Skeleton className="h-6 w-20 rounded-full" />
            </div>
          </div>
          <Skeleton className="size-8" />
        </div>

        <div className="flex w-full gap-10">
          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[54px]" />
              <Skeleton className="h-6 w-[54px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[100px]" />
              <Skeleton className="h-6 w-[84px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[60px]" />
              <Skeleton className="h-6 w-[110px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[110px]" />
              <Skeleton className="h-6 w-[60px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[96px]" />
              <Skeleton className="h-6 w-[120px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[134px]" />
              <Skeleton className="h-6 w-[140px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[78px]" />
              <Skeleton className="h-6 w-[100px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[80px]" />
              <Skeleton className="h-6 w-[54px]" />
            </div>
          </div>

          <Separator orientation="vertical" className="bg-grey-200" />

          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[151px]" />
              <Skeleton className="h-6 w-[54px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[142px]" />
              <Skeleton className="h-6 w-[148px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[132px]" />
              <Skeleton className="h-6 w-[148px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[94px]" />
              <Skeleton className="h-6 w-[32px]" />
            </div>
            <Separator orientation="horizontal" className="bg-grey-200" />
            <div className="flex w-full flex-col gap-2">
              <div className="flex w-full items-center justify-between gap-2">
                <Skeleton className="h-6 w-[240px]" />
                <Skeleton className="h-6 w-[54px]" />
              </div>
              <Skeleton className="h-[48px] w-full" />
            </div>
            <Separator orientation="horizontal" className="bg-grey-200" />
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[40px]" />
              <Skeleton className="h-6 w-[100px]" />
            </div>
            <div className="flex w-full items-center justify-between gap-2 pb-4">
              <Skeleton className="h-6 w-[131px] rounded-full" />
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col gap-6 rounded-lg border border-grey-200 bg-white shadow-custom-light">
        <Skeleton className="mx-4 mt-6 h-7 w-16" />
        <div className="flex flex-col gap-4 py-4">
          <Skeleton className="mx-5 h-5 w-auto" />
          <Separator className="bg-gray-200" />
          <Skeleton className="mx-5 h-5 w-auto" />
          <Separator className="bg-gray-200" />
          <Skeleton className="mx-5 h-5 w-auto" />
        </div>
      </div>
    </div>
  );
};
