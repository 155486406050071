import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { AccessDenied, Heading, Text } from '@purple/ui';
import { MyGroupActions } from '~/modules/Actions';

export const MyGroupActionsPage: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-col gap-1">
        <Heading tag="h1" variant="size-28" className="font-semibold text-grey-title">
          My Group Actions
        </Heading>
        <Text className="text-sm text-grey-950">Keep track of / manage your group actions</Text>
      </div>
      <Guard
        requiredPermissions={[
          APP_PERMISSIONS.CAN_ACCESS_EWS_VISUALIZER_OF_STUDENTS,
          APP_PERMISSIONS.CAN_ACCESS_MY_GROUPS,
        ]}
        fallback={(
          <AccessDenied
            accessDeniedMessage="You do not have permission to view my group actions."
            className="size-full rounded-lg border border-grey-200 bg-white p-6 py-24"
          />
        )}
      >
        <MyGroupActions />
      </Guard>
    </div>
  );
};
