import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@purple/ui';
import { useStudentAssistance } from '~/hooks/redux';
import { useSchoolList } from '~/queries';
import { useArabicLanguage } from '../hooks';
import type React from 'react';

type TSchoolComboBoxProperties = {
  /**
   * The selected value of the combobox.
   */
  value: string;
  /**
   * Whether the combobox is in an error state.
   * @default false
   */
  isError?: boolean;
  /**
   * Callback that is called when the value of the combobox changes.
   */
  onChange: (value: string) => void;
};

export const SchoolComboBox: React.FC<TSchoolComboBoxProperties> = (props) => {
  const { value, isError = false, onChange } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { setSchoolGrades } = useStudentAssistance();
  const { data: schoolsData, isFetching } = useSchoolList({ search: debouncedSearchValue });
  const { t } = useTranslation('saf');
  const { isArabic } = useArabicLanguage();

  const schools = useMemo(() => schoolsData?.results ?? [], [schoolsData?.results]);
  const selectedSchool = useMemo(() => schools.find((school) => school.id === value) ?? null, [value, schools]);

  const selectValueHandler = useCallback(
    (newValue: string) => {
      const school = schools.find((item) => item.id === newValue);
      if (school) {
        setSchoolGrades(school.grades);
      }
      onChange(newValue);
      setOpen(false);
    },
    [onChange, schools, setSchoolGrades],
  );

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 700);

  const searchChangeHandler = useCallback(
    (newSearchValue: string) => {
      setSearchValue(newSearchValue);
      debouncedSearch(newSearchValue);
    },
    [debouncedSearch],
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          dir={isArabic ? 'rtl' : 'ltr'}
          className={cn(
            'w-full justify-between border-grey-300 px-3 font-normal text-grey-950 hover:border-brand-blue-700 hover:bg-transparent focus-visible:border-brand-blue-700 focus-visible:bg-transparent active:border-brand-blue-700 active:bg-transparent active:text-current',
            {
              'text-grey-600': selectedSchool === null,
              'border-error-main': isError,
              '[&>svg]:ml-0 [&>svg]:mr-auto': isArabic,
            },
          )}
        >
          {selectedSchool?.name ?? t('form.student.school.placeholder')}
          <PurpleIcon name="selector" className="ml-auto size-5 shrink-0 text-grey-200" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0" useTriggerWidth>
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search school..."
            className="h-9"
            value={searchValue}
            onValueChange={searchChangeHandler}
          />
          {isFetching
            ? (
                <CommandLoading className="flex w-full items-center justify-center py-6">
                  <PurpleIcon name="loader" className="size-5 animate-spin" />
                </CommandLoading>
              )
            : (
                <CommandEmpty>School not found.</CommandEmpty>
              )}
          <CommandList>
            <CommandGroup>
              {schools.map(({ id, name }) => (
                <CommandItem key={id} value={id} onSelect={selectValueHandler}>
                  {name}
                  {value === name && <PurpleIcon name="check" className="ml-auto size-4 text-brand-blue-700" />}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
