import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { LocalStorageKey, USER_QUERY_KEYS } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { showErrorToast } from '~/shared/lib';

export const SsoComplete = () => {
  const [searchParameters] = useSearchParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const accessToken = searchParameters.get('access');
  const refreshToken = searchParameters.get('refresh');

  const isValidToken = useCallback((token: string) => token && token.length > 0, []);

  const handleError = useCallback(() => {
    showErrorToast('System message', 'Unable to login, please try again');
    navigate(AppRoutes.Auth.SignIn.Root.path, { replace: true });
  }, [navigate]);

  const completeSso = useCallback(() => {
    if (!accessToken || !refreshToken) {
      handleError();
      return;
    }

    if (!isValidToken(accessToken) || !isValidToken(refreshToken)) {
      handleError();
      return;
    }

    localStorage.setItem(LocalStorageKey.Auth.Token.AccessToken, accessToken);
    localStorage.setItem(LocalStorageKey.Auth.Token.RefreshToken, refreshToken);
    navigate(AppRoutes.Auth.SignIn.Root.path, { replace: true });
    queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.CURRENT] });
  }, [accessToken, refreshToken, isValidToken, handleError, queryClient, navigate]);

  useEffect(() => {
    completeSso();
  }, [completeSso]);

  return (
    <div className="flex h-40 flex-col items-center justify-center gap-3">
      <PurpleIcon name="loader" className="size-9 animate-spin" />
      <Text>Checking provided information. Please wait...</Text>
    </div>
  );
};
