import type { TDaysOfWeek } from '../days';
import type { TSafSubmitterType } from '../saf';
import type { ValueOf } from '../utils';
import type { IUser } from './user';

export const DISTRICT_STATUS = {
  PUBLISHED: 'published',
  DEACTIVATED: 'deactivated',
  DRAFT: 'draft',
} as const;
export type TDistrictStatus = ValueOf<typeof DISTRICT_STATUS>;

export const DISTRICTS_FLAGS = {
  ATTENDANCE: 'attendance',
  BEHAVIOR: 'behavior',
  GRADES: 'grades',
  SUBPOPS: 'subpops',
  HEALTH: 'health',
} as const;
export type TDistrictsFlags = ValueOf<typeof DISTRICTS_FLAGS>;

export const DISTRICT_SETUP_STEP = {
  INITIAL: 'initial',
  BASIC: 'basic',
  BRANDING: 'branding',
  SAF_PROCESS: 'saf_process',
  USERS_AND_PERMISSIONS: 'users_and_permissions',
  // COMMENT: DATA_UPLOADS - it is SIS Data mapping step
  // BE team use naming like 'DATA_UPLOADS'
  DATA_UPLOADS: 'data_uploads',
  FINISHED: 'finished',
} as const;
export type TDistrictSetupStep = ValueOf<typeof DISTRICT_SETUP_STEP>;

export const DistrictView = {
  PUBLISHED: 'published-view',
  DRAFT: 'draft-view',
} as const;
export type TDistrictView = ValueOf<typeof DistrictView>;

export const DistrictDetailsView = {
  DETAILS: 'details',
  BRANDING: 'branding',
  NAVIGATION: 'navigation',
  SAF_PROCESS: 'saf-process',
  USERS: 'users',
  SIS_DATA: 'sis-data',
  SSO_SETTINGS: 'sso-settings',
} as const;
export type TDistrictDetailsView = ValueOf<typeof DistrictDetailsView>;

export const DistrictUserStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending_invitation',
} as const;
export type TDistrictUserStatus = ValueOf<typeof DistrictUserStatus>;

export type TDistrictNeed = {
  id: string;
  name: string;
  translations: Record<string, string>;
  submitter_types: TSafSubmitterType[];
};

export type TDistrictBusinessDay = {
  id: number;
  day: TDaysOfWeek;
  start_time: string;
  end_time: string;
  is_active: boolean;
};

export type TDistrictHoliday = {
  id: number;
  name: string;
  start_datetime: string;
  end_datetime: string;
  is_recurring: boolean;
};

export type TDistrict = {
  id: number;
  name: string;
  description?: string;
  email?: string | null;
  subdomain?: string | null;
  logo?: string | null;
  branding_color: string;
  branding_font?: string | null;
  timezone?: string;
  school_year_start_datetime?: string | null;
  school_year_end_datetime?: string | null;
  current_setup_step: unknown;
  status: TDistrictStatus;
  regions: string[];
  states: string[];
  can_community_member_submit_anonymously: boolean;
  can_parent_submit_anonymously: boolean;
  can_student_submit_anonymously: boolean;
  can_teacher_submit_anonymously: boolean;
  show_city_or_region_hierarchy_field: boolean;
  introduction_text?: string | Record<string, unknown> | null;
  acknowledgement_text?: string | Record<string, unknown> | null;
  disclaimer_text?: string | Record<string, unknown> | null;
  special_ed_text?: string | Record<string, unknown> | null;
  needs?: TDistrictNeed[] | null;
  responsible_for_setup?: Pick<IUser, 'avatar' | 'email' | 'full_name' | 'id' | 'title'> | null;
  address?: string | null;
  business_days?: TDistrictBusinessDay[];
  holidays?: TDistrictHoliday[];
  published_at?: string | null;
  published_by?: Pick<IUser, 'avatar' | 'email' | 'full_name' | 'id' | 'title'> | null;
  created_at?: string;
  created_by?: Pick<IUser, 'avatar' | 'email' | 'full_name' | 'id' | 'title'> | null;
  updated_at?: string;
  last_modified_by?: Pick<IUser, 'avatar' | 'email' | 'full_name' | 'id' | 'title'> | null;
  provider_type: 'microsoft' | 'google' | 'classlink' | null;
};

export type TDistrictInfo = Pick<
  TDistrict,
  'id' | 'branding_color' | 'branding_font' | 'description' | 'logo' | 'name' | 'regions' | 'status' | 'subdomain' | 'timezone' | 'provider_type'
> & {
  flags_enabled: {
    [key in TDistrictsFlags]: boolean;
  };
};

export type TDistrictCustomPage = {
  id: string;
  name: string;
  path: string;
  embeded_code: string;
  is_open_new_tab: boolean;
  is_active: boolean;
  district: number;
  order_number: number;
  created_at?: string;
  created_by?: Pick<IUser, 'avatar' | 'email' | 'full_name' | 'id' | 'title'> | null;
};

export type TDistrictBusinessDaySetUp = Omit<TDistrictBusinessDay, 'id' | 'start_time' | 'end_time'> & {
  id?: number;
  // example: '08:00:00'
  start_time: string;
  // example: '17:00:00'
  end_time: string;
};

export type TDistrictBusinessDayRequest = Omit<TDistrictBusinessDaySetUp, 'start_time' | 'end_time'> & {
  start_time: string;
  end_time: string;
};

export type TDistrictHolidaySetUp = Omit<TDistrictHoliday, 'id' | 'start_datetime' | 'end_datetime'> & {
  id?: number;
  name: string;
  start_datetime: string;
  end_datetime: string;
  is_recurring: boolean;
};

export type TDistrictProunitasRole = {
  id: number;
  name: string;
};

export type TDistrictLeaderUser = {
  id?: string;
  email: string;
  prounitas_roles: TDistrictProunitasRole[];
};

export type TDistrictDraftDetail = {
  id: number;
  name: string;
  subdomain: string;
  no_district_leader_available: boolean;
  users: TDistrictLeaderUser[];
  states: string[];
  regions: string[];
  school_year_start_datetime: string;
  school_year_end_datetime: string;
  timezone: string;
  business_days: TDistrictBusinessDay[];
  holidays: TDistrictHolidaySetUp[];
  status: TDistrictStatus;
  current_setup_step: TDistrictSetupStep;
  responsible_for_setup: string | null;
  logo: string | null;
  branding_color: string;
  // SAF PROCESS FIELDS
  can_student_submit_anonymously: boolean;
  can_teacher_submit_anonymously: boolean;
  can_parent_submit_anonymously: boolean;
  can_community_member_submit_anonymously: boolean;
  introduction_text: string;
  acknowledgement_text: string;
  disclaimer_text: string;
  special_ed_text: string;
  needs: TDistrictNeed[];
  show_city_or_region_hierarchy_field: boolean;
  email: string | null;
};

export type TDistrictUpdateResponsibleForSetupResponse = {
  id: number;
  responsible_for_setup: string;
};
