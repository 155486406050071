import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { cutGradeName, snakeCaseToCapitalized } from '@purple/shared-utils';
import { Checkbox, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import type { ColumnDef } from '@tanstack/react-table';
import type { TCrisisActivity } from '@purple/shared-types';

type TCrisisOptions = {
  isAllSelected?: boolean;
  selectedActivityIds?: string[];
  onActivitySelect?: (crisis: any) => void;
  onSelectAll?: (checked: boolean) => void;
};

type TActivityColumns = (options: TCrisisOptions) => ColumnDef<TCrisisActivity>[];

export const useCrisisCallTrackerColumns: TActivityColumns = (options) => {
  const { isAllSelected, onActivitySelect, onSelectAll, selectedActivityIds = [] } = options;

  const columns: ColumnDef<TCrisisActivity>[] = useMemo(
    () => [
      {
        accessorKey: 'select',
        meta: { className: 'max-w-[60px] min-w-[60px] w-[60px] leading-[0]' },
        header: () => <Checkbox variant="checkedAll" onCheckedChange={onSelectAll} checked={isAllSelected} />,
        cell: ({ row: { original } }) => (
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="leading-[0]">
                <Checkbox
                  onCheckedChange={() => onActivitySelect?.(original)}
                  checked={selectedActivityIds.includes(original.id)}
                />
              </span>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>Toggle selection</TooltipContent>
            </TooltipPortal>
          </Tooltip>
        ),
      },
      {
        accessorKey: 'name',
        meta: { className: 'max-w-[200px]' },
        header: () => (
          <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
            Activity Name
          </Text>
        ),
        cell: ({ row: { original } }) => (
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                to={AppRoutes.App.Community.Details.makePath(original.id)}
                target="_blank"
                rel="noreferrer"
                className="inline-block max-w-[160px] cursor-pointer truncate align-middle font-primary font-medium capitalize text-brand-blue-700 underline-offset-2 transition-colors hover:text-brand-blue-800 hover:underline"
              >
                {original.name}
              </Link>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent withArrow={false}>{original.name}</TooltipContent>
            </TooltipPortal>
          </Tooltip>
        ),
      },
      {
        accessorKey: 'crisis-type',
        header: () => (
          <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
            Crisis Type
          </Text>
        ),
        cell: ({ row: { original } }) => (
          <Tooltip>
            <TooltipTrigger asChild>
              <Text variant="size-14" type="body-500" className="truncate text-grey-950">
                {original.crisis_type.map((type) => snakeCaseToCapitalized(type)).join(', ')}
              </Text>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent withArrow={false}>
                {original.crisis_type.map((type) => snakeCaseToCapitalized(type)).join(', ')}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        ),
      },
      {
        accessorKey: 'grade-level',
        meta: { className: 'min-w-[125px]' },
        header: () => (
          <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
            Grade Level
          </Text>
        ),
        cell: ({ row: { original } }) => (
          <Text variant="size-14" type="body-500" className="truncate text-center text-grey-950">
            {cutGradeName(snakeCaseToCapitalized(original.crisis_grade_level))}
          </Text>
        ),
      },
      {
        accessorKey: 'school',
        header: () => (
          <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
            School
          </Text>
        ),
        cell: ({ row: { original } }) => (
          <Link
            to={AppRoutes.App.School.Root.makePath(original.school?.id)}
            target="_blank"
            rel="noreferrer"
            className="inline-block max-w-[160px] cursor-pointer truncate align-middle font-primary font-medium capitalize text-brand-blue-700 underline-offset-2 transition-colors hover:text-brand-blue-800 hover:underline"
          >
            {original.school?.name}
          </Link>
        ),
      },
      {
        accessorKey: 'contact-name',
        header: () => (
          <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
            Contact Name
          </Text>
        ),
        cell: ({ row: { original } }) => (
          <Text variant="size-14" type="body-500" className="truncate text-grey-950">
            {original.campus_contact_name && original.campus_contact_name?.length > 0
              ? original.campus_contact_name
              : '—'}
          </Text>
        ),
      },
      {
        accessorKey: 'title',
        header: () => (
          <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
            Title
          </Text>
        ),
        cell: ({ row: { original } }) => (
          <Text variant="size-14" type="body-500" className="truncate capitalize text-grey-950">
            {original.campus_contact_title ?? '—'}
          </Text>
        ),
      },
      {
        accessorKey: 'email',
        header: () => (
          <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
            Email
          </Text>
        ),
        cell: ({ row: { original } }) => (
          <Text variant="size-14" type="body-500" className="truncate text-grey-950">
            {original.contact_email}
          </Text>
        ),
      },
      {
        accessorKey: 'phone',
        header: () => (
          <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
            Phone number
          </Text>
        ),
        cell: ({ row: { original } }) => (
          <Text variant="size-14" type="body-500" className="truncate text-grey-950">
            {original.contact_phone_number ?? '—'}
          </Text>
        ),
      },
      {
        accessorKey: 'assigned-to',
        header: () => (
          <Text variant="size-12" type="body-600" className="uppercase text-grey-600">
            Assigned To
          </Text>
        ),
        cell: ({ row: { original } }) => (
          <Tooltip>
            <TooltipTrigger asChild>
              <Text variant="size-14" type="body-500" className="truncate text-grey-950">
                {original.assigned_to?.full_name ?? '—'}
              </Text>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent withArrow={false}>{original.assigned_to?.full_name ?? '—'}</TooltipContent>
            </TooltipPortal>
          </Tooltip>
        ),
      },
    ],
    [isAllSelected, onActivitySelect, onSelectAll, selectedActivityIds],
  );

  return columns;
};
