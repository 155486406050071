import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { getInitialsFromName, NOTE_QUERY_KEYS, STUDENTS_QUERY_KEYS } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Checkbox,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
  ScrollArea,
  Separator,
  Text,
} from '@purple/ui';
import { MinimalTiptapEditor } from '~/components/RichTextEditor';
import { useModal } from '~/hooks';
import { useNoteById, useUpdateNote, useUsersSearch } from '~/queries';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import { addStudentNoteSchema } from './add-student-note-schema';
import { StudentsNoteFormSkeleton } from './StudentNoteFormSkeleton';
import type * as z from 'zod';
import type { IStudentDetailsResponse } from '@purple/shared-services';
import type { TUpdateNotePayload } from '@purple/shared-types';

type TFormSchema = z.infer<typeof addStudentNoteSchema>;

type TStudentNoteDialogProperties = {
  noteId: string | null;
  modalType: 'create' | 'edit' | 'preview';
  onModeChange: (mode: 'create' | 'edit' | 'preview') => void;
  setSelectedNoteId: (noteId: string | null) => void;
};

export const StudentNoteDialog = ({
  noteId,
  setSelectedNoteId,
  modalType,
  onModeChange,
}: TStudentNoteDialogProperties) => {
  const queryClient = useQueryClient();
  const { studentId } = useParams();

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { isOpen, toggleModal } = useModal('student-note');

  const isEditMode = useMemo(() => modalType === 'edit', [modalType]);
  const isPreviewMode = useMemo(() => modalType === 'preview', [modalType]);

  // Get students details information from the cached data form Tanstack query cache
  // This is used to get the student information to display in the dialog and do not add unnecessary redux state for this
  // If the query will not exist in the cache, it will return undefined
  const studentQueryCashedData = queryClient.getQueryData<IStudentDetailsResponse>([
    STUDENTS_QUERY_KEYS.GET_STUDENT_DETAILS,
    studentId,
  ]);

  const { mutate, isPending } = useUpdateNote();

  const { data: noteData, isFetching } = useNoteById({
    noteId: noteId as string,
    enabled: (isEditMode || isPreviewMode) && isOpen,
  });

  const { data: sharedUsers, isFetching: isUserFetching } = useUsersSearch({
    enabled: isOpen,
    search: debouncedSearchValue,
  });

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  const multiSelectOptions = useMemo(
    () =>
      sharedUsers?.results.map((user) => ({
        ...user,
        label: user.full_name ?? user.email,
        value: user.id,
      })) ?? [],
    [sharedUsers],
  );

  const defaultValues = useMemo(() => {
    return {
      title: noteData?.title ?? '',
      text: noteData?.text ?? '',
      is_private: noteData?.is_private ?? false,
      viewers: noteData?.viewers ?? [],
    };
  }, [noteData]);

  const form = useForm<TFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(addStudentNoteSchema),
    defaultValues,
  });

  const isPrivateNote = form.watch('is_private');

  const renderModalTitle = () => {
    if (isEditMode) return 'Edit Note';
    if (isPreviewMode) return 'Note Preview';
    return 'New Note';
  };

  const handleCloseModal = () => {
    setSelectedNoteId(null);
    onModeChange('create');
    form.reset();
    form.clearErrors();
    toggleModal(!isOpen);
  };

  const handleFormSubmit = (formData: TFormSchema) => {
    if (!noteId) {
      const errorMessage = isEditMode ? 'Unable to update student note' : 'Unable to create student note';
      showErrorToast(errorMessage, 'System Error');
      return;
    }

    const payload: TUpdateNotePayload = {
      is_draft: false,
      ...formData,
    };

    mutate(
      { noteId, noteData: payload },
      {
        onSuccess: () => {
          const successMessage = isEditMode ? 'Student note updated successfully' : 'Student note created successfully';
          showSuccessToast(successMessage, 'System Message');
          queryClient.invalidateQueries({ queryKey: [NOTE_QUERY_KEYS.GET_NOTES_LIST] });
          handleCloseModal();
        },
        onError: () => {
          const errorMessage = isEditMode ? 'Unable to update student note' : 'Unable to create student note';
          showErrorToast(errorMessage, 'System Error');
        },
      },
    );
  };

  useEffect(() => {
    form.reset(defaultValues);
  }, [form, defaultValues]);

  return (
    <Dialog open={isOpen} onOpenChange={handleCloseModal}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
        <DialogHeader className="flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle className='className="leading-7 tracking-normal'>{renderModalTitle()}</DialogTitle>
            <DialogDescription asChild>
              <Text variant="size-14" type="body-400" className="text-grey-600">
                Related to:
                {' '}
                <span className="font-semibold">
                  {studentQueryCashedData?.details.full_name || 'User without full name'}
                </span>
              </Text>
            </DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
          <Form providerProps={form} className="space-y-4 p-6 pr-[30px]">
            {isFetching
              ? (
                  <StudentsNoteFormSkeleton formMode={modalType} />
                )
              : (
                  <>
                    <FormField
                      control={form.control}
                      name="title"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel required>Title</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              disabled={isPreviewMode}
                              isError={!!form.formState.errors.title}
                              placeholder="Enter note title"
                              type="text"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="text"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel required>Note Text</FormLabel>
                          <FormControl>
                            <MinimalTiptapEditor
                              {...field}
                              value={noteData?.text}
                              disabled={isPreviewMode}
                              objectId={noteId as string}
                              onValueChange={field.onChange}
                              outputValue="json"
                              placeholder="Enter a note here"
                              contentClass="h-[300px]"
                              className={cn('w-full max-w-[790px] overflow-y-auto', {
                                'border-error-main': form.formState.errors.text,
                              })}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {!isPreviewMode && (
                      <>
                        <FormField
                          control={form.control}
                          name="is_private"
                          render={({ field }) => (
                            <FormItem className="flex gap-2 space-y-0">
                              <FormControl>
                                <Checkbox
                                  {...field}
                                  value={field.value.toString()}
                                  checked={field.value}
                                  onCheckedChange={(value) => {
                                    field.onChange(value);
                                    form.setValue('viewers', []);
                                  }}
                                  className="relative top-0.5 size-4"
                                />
                              </FormControl>
                              <div className="flex flex-col">
                                <FormLabel className="text-sm font-medium">Mark as my note</FormLabel>
                                <FormDescription className="text-sm font-normal">
                                  This note will be visible to you in Purple and designated district leaders with specific
                                  access for coaching, subpoena and disclosure requests.
                                </FormDescription>
                              </div>
                            </FormItem>
                          )}
                        />
                        {isPrivateNote && (
                          <FormField
                            control={form.control}
                            name="viewers"
                            render={({ field, fieldState }) => (
                              <FormItem>
                                <FormLabel>Share with</FormLabel>
                                <FormControl>
                                  <MultiSelect
                                    {...field}
                                    isError={!!fieldState.error}
                                    selectedOptions={multiSelectOptions.filter((tag) => field.value.includes(tag.value))}
                                    showSearch
                                    loading={isUserFetching}
                                    shouldFilter={false}
                                    searchPlaceholder="Search by user name or email"
                                    placeholder="Select users"
                                    modalPopover
                                    onOptionChange={field.onChange}
                                    onSearchChange={debouncedSearch}
                                  >
                                    {multiSelectOptions.map((option) => (
                                      <MultiSelectItem
                                        key={option.value}
                                        value={option.value}
                                        option={option}
                                        isSelected={field.value.includes(option.value)}
                                        customContent
                                      >
                                        <>
                                          {field.value.includes(option.value) && (
                                            <PurpleIcon
                                              name="check"
                                              className="absolute left-4 top-1/2 size-4 -translate-y-1/2 text-brand-blue-700"
                                            />
                                          )}
                                          <div className="flex items-center gap-2">
                                            <Avatar size={32}>
                                              <AvatarImage
                                                src={option.avatar ?? undefined}
                                                alt={option.full_name ?? option.email}
                                              />
                                              <AvatarFallback className="bg-grey-200">
                                                {getInitialsFromName(option.full_name ?? '- -')}
                                              </AvatarFallback>
                                            </Avatar>
                                            <div className="flex flex-col gap-1">
                                              <strong className="line-clamp-1 text-xs font-medium text-grey-950">
                                                {option.full_name ?? option.email}
                                              </strong>
                                              <span className="line-clamp-1 text-xs text-grey-600">{option.email}</span>
                                            </div>
                                          </div>
                                        </>
                                      </MultiSelectItem>
                                    ))}
                                  </MultiSelect>
                                </FormControl>
                                <FormDescription>Access for all users will be &quot;View Only&quot;</FormDescription>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
          </Form>
        </ScrollArea>
        {!isPreviewMode && (
          <>
            <Separator />
            <DialogFooter>
              <Button variant="link" className="w-auto px-2 py-[10px] text-brand-blue-700" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button onClick={form.handleSubmit(handleFormSubmit)} isLoading={isPending} disabled={isPending}>
                Save
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
