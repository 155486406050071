import { useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { PurpleIcon } from '@purple/icons';
import { formateDateShortMonth } from '@purple/shared-utils';
import { Banner, BannerDescription } from '@purple/ui';
import { LinkButton } from '~/components/LinkButton';
import { AppRoutes } from '~/constants/routes/routes';
import { useGoBack } from '~/hooks';
import { MyFiles, ProfileSidebar } from '~/modules/Profile';
import { useProfile } from '~/queries';

export const ProfilePage: React.FC = () => {
  const { data } = useProfile();

  const isEmailChangeRequestExist = useMemo(() => Boolean(data?.account_details.change_email_request), [data]);
  const newEmail = useMemo(() => data?.account_details?.change_email_request?.new_email, [data]);
  const expiredAt = useMemo(() => data?.account_details?.change_email_request?.expired_at, [data]);

  const { onGoBack } = useGoBack({
    fallbackPath: AppRoutes.App.Home.Root.path,
  });

  return (
    <div className="flex w-full flex-col gap-6">
      {isEmailChangeRequestExist && (
        <Banner variant="warning" className="flex">
          <div className="flex items-center gap-2">
            <PurpleIcon name="exclamation-circle" className="size-[20px] text-warning-main" />
            <BannerDescription>
              You have updated your email
              {' '}
              <span className="font-semibold">{newEmail}</span>
              . Please confirm it before
              {' '}
              <span className="font-semibold">{expiredAt ? formateDateShortMonth(expiredAt) : '-'}</span>
            </BannerDescription>
          </div>
        </Banner>
      )}
      <LinkButton to={AppRoutes.App.Home.Root.path} iconName="chevron-left" onClick={onGoBack}>
        Back to Home Page
      </LinkButton>
      <AnimatePresence mode="wait">
        <motion.div
          key="profile"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -30 }}
          transition={{ duration: 0.3 }}
          className="flex w-full gap-6"
        >
          <ProfileSidebar data={data} />
          <MyFiles />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
