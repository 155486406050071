import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch } from '@purple/hooks';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { AppSelectedFiltersList, Heading, TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { useUsersWithAccessFilterOptions, useUsersWithAccessList } from '~/queries/schools/users-with-access';
import { UserHeaderSection, UsersHeaderSectionSkeleton } from './UsersHeaderSection';
import { usersWithAccessColumns } from './usersWithAccessColumns';

export const UsersWithAccess: React.FC = () => {
  const { schoolId } = useParams();

  const [searchParameters] = useSearchParams();

  const { debounceSearch, search } = useSearch();

  const sort = searchParameters.get(SORT_QUERY_NAME) || '';
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const { data: filterOptions, isLoading: isFiltersLoading } = useUsersWithAccessFilterOptions(schoolId as string);

  const { isLoading: isDataLoading, data } = useUsersWithAccessList({
    schoolId: schoolId as string,
    queryParams: {
      search: debounceSearch,
      limit,
      offset,
      ordering: sort,
      title: searchParameters.get('title'),
      is_active: searchParameters.get('is_active'),
    },
  });

  const isDataNotExist = useMemo(
    () => (!data?.results || data.results.length === 0) && (!isDataLoading || !isFiltersLoading),
    [data?.results, isDataLoading, isFiltersLoading],
  );

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  if (!schoolId) {
    return null;
  }

  return (
    <>
      <div className="flex px-4 pb-4 pt-6">
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          Users with Access
        </Heading>
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex px-4">
          {isFiltersLoading || !filterOptions
            ? (
                <UsersHeaderSectionSkeleton />
              )
            : (
                <div className="flex flex-col gap-4">
                  <UserHeaderSection filterConfig={filterOptions} />
                  <AppSelectedFiltersList config={filterOptions} maxDisplayedFilters={8} />
                </div>
              )}
        </div>

        <div className="flex flex-col gap-5">
          <div>
            <DataTable
              columns={usersWithAccessColumns}
              data={data?.results || []}
              isLoading={isDataLoading}
              isFiltersApplied={Boolean(search)}
              emptyStateTitle="No Users found"
              emptyStateMessage="There are no Users to display. If it an error, please contact support."
              skeleton={<DataTableSkeleton rows={limit} />}
            />
            {!isDataNotExist && (
              <TableFooterSection
                currentPage={page}
                pageCount={pageCount}
                onPageChange={onPageChange}
                rowsPerPage={limit}
                onRowsPerPageChange={onLimitChange}
                totalRows={data?.count || 0}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
