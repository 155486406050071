import { PurpleIcon } from '@purple/icons';
import { FILE_EXTENSION_ICON_MAP } from '@purple/shared-types';
import { getFileInfo } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import type { PurpleIconType } from '@purple/icons';

type TUploadedFileCardProperties = {
  file: File;
  onRemove: () => void;
};

const UploadedFileCard = ({ file, onRemove }: TUploadedFileCardProperties) => {
  const { name, extension } = getFileInfo(file);
  const iconName = FILE_EXTENSION_ICON_MAP[extension] || 'file';

  return (
    <div className="flex w-full items-center justify-between gap-2 rounded-lg border border-grey-300 bg-grey-50 px-4 py-3">
      <div className="flex items-center gap-2">
        <PurpleIcon name={iconName as PurpleIconType} className="size-[28px]" />
        <Text variant="size-14" type="body-500" className="text-grey-950">
          {name}
        </Text>
      </div>
      <div className="flex items-center gap-4">
        <div className="rounded-full bg-success-main">
          <PurpleIcon name="check" className="size-[20px] text-white" />
        </div>
        <button type="button" onClick={onRemove}>
          <PurpleIcon name="trash" className="size-[20px] text-error-main" />
        </button>
      </div>
    </div>
  );
};

export { UploadedFileCard };
