import { PurpleIcon } from '@purple/icons';
import { Text } from '@purple/ui';
import type { TSafListManagerDto } from '@purple/shared-types';

type TSubmitterColumnProperties = {
  data: TSafListManagerDto;
};

const SubmitterColumn = ({ data }: TSubmitterColumnProperties) => {
  const { submitter, has_unread_email } = data;
  const fullName = submitter ? `${submitter.first_name} ${submitter.last_name}` : 'Anonymous';

  return (
    <div className="flex items-center justify-between">
      <Text variant="size-14" type="body-500" className="line-clamp-2 text-grey-950">
        {fullName}
      </Text>
      {/* TODO: make it clickable when this functional will be available */}
      {Boolean(has_unread_email) && (
        <PurpleIcon name="mail" className="size-4 min-h-4 min-w-4 cursor-pointer text-grey-600" />
      )}
    </div>
  );
};

export { SubmitterColumn };
