import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { PurpleIcon, type PurpleIconType } from '@purple/icons';
import { cn, NavigationMenuLink } from '@purple/ui';
import type { To } from 'react-router-dom';

type TNavigationListItemProperties = React.ComponentPropsWithoutRef<'a'> & {
  /**
   * The icon to display on the left side of the list item.
   */
  iconName: PurpleIconType;
  /**
   * The destination of the link.
   */
  to: To;
  /**
   * Whether the list item is active.
   */
  active?: boolean;
};

export const NavigationListItem = forwardRef<React.ElementRef<'a'>, TNavigationListItemProperties>(
  ({ className, children, to, iconName, active = false, ...props }, reference) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <Link
            ref={reference}
            to={to}
            className={cn(
              'relative flex w-full cursor-pointer select-none items-center rounded-md px-3 py-2.5 font-primary text-sm font-medium text-grey-950 outline-none hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
              active ? 'pointer-events-none cursor-default bg-brand-blue-100 text-brand-blue-700' : 'text-grey-950',
              className,
            )}
            {...props}
            contentEditable={undefined}
          >
            <PurpleIcon className="mr-2 size-5 shrink-0" name={iconName} />
            {children}
          </Link>
        </NavigationMenuLink>
      </li>
    );
  },
);
NavigationListItem.displayName = 'NavigationListItem';
