import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Checkbox,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Label,
  Separator,
  Textarea,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { useSafNoNeed } from '~/queries';
import { SAF_NO_NEED_REASON_OPTIONS } from './constants';
import { safNoNeedSchema } from './schema';
import type * as z from 'zod';

type TSafNoNeedModalProperties = {
  safId: string;
  onCloseCallBack?: () => void;
};

const SafNoNeedModal = ({ safId, onCloseCallBack }: TSafNoNeedModalProperties) => {
  const { isOpen, toggleModal } = useModal(ModalType.SAF_NO_NEED);

  const { mutate: safNoNeed, isPending } = useSafNoNeed();

  const form = useForm<z.infer<typeof safNoNeedSchema>>({
    resolver: zodResolver(safNoNeedSchema),
    mode: 'onChange',
    defaultValues: {
      no_need_reason: [],
      no_need_reason_text: '',
    },
  });

  const toggleModalCloseHandler = () => {
    onCloseCallBack?.();
    toggleModal(!isOpen);
    form.reset();
  };

  const noNeedHandler = (data: z.infer<typeof safNoNeedSchema>) => {
    safNoNeed(
      { safId, body: data },
      {
        onSuccess: () => {
          toggleModalCloseHandler();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <Form providerProps={form} onSubmit={form.handleSubmit(noNeedHandler)}>
          <DialogHeader className="flex-row items-center justify-between">
            <DialogTitle>No Need</DialogTitle>
            <DialogDescription className="sr-only">Saf No Need</DialogDescription>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          <div className="flex w-full flex-col gap-4 p-6">
            <FormField
              control={form.control}
              name="no_need_reason"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Reason</FormLabel>
                  <FormControl>
                    <div className="flex flex-col gap-1">
                      {SAF_NO_NEED_REASON_OPTIONS.map(({ value, label }) => {
                        return (
                          <div key={value} className="flex items-center gap-2">
                            <Checkbox
                              id={value}
                              value={value.toString()}
                              checked={field.value.includes(value)}
                              onCheckedChange={(newValue) => {
                                const newReasons = newValue
                                  ? [...field.value, value]
                                  : field.value.filter((reason) => reason !== value);
                                field.onChange(newReasons);
                              }}
                              className="size-4"
                            />
                            <Label htmlFor={value} className="capitalize leading-5">
                              {label}
                            </Label>
                          </div>
                        );
                      })}
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="no_need_reason_text"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Details</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      isError={!!form.formState.errors.no_need_reason_text}
                      placeholder="Enter details here"
                      responsiveHeight
                      className="min-h-[80px] resize-none"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Separator />
          <DialogFooter>
            <Button type="button" variant="tertiary" onClick={toggleModalCloseHandler}>
              Cancel
            </Button>
            <Button type="submit" disabled={isPending} isLoading={isPending}>
              Submit
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { SafNoNeedModal };
