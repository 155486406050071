import { useMemo } from 'react';
import { useOverridePointerEvents } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Dialog, DialogDescription, DialogTitle, FileUploadContent, Heading } from '@purple/ui';
// hooks
import { useModal } from '~/hooks';
// helpers
import { ModalType } from '~/constants/modals';
// components
import { UploadFilePreviewHeader } from './UploadFilePreviewHeader';
// types
import type React from 'react';

type TUploadFilePreviewDialogProperties = {
  uploadedFile: File | null;
  selectedFileId: string | null;
  setSelectedFileId: (fileId: string | null) => void;
  setUploadedFile: (file: File | null) => void;
};

export const UploadFilePreviewDialog: React.FC<TUploadFilePreviewDialogProperties> = (props) => {
  const { uploadedFile, selectedFileId, setSelectedFileId, setUploadedFile } = props;

  const { isOpen, toggleModal } = useModal(ModalType.UPLOAD_FILE_PREVIEW);
  useOverridePointerEvents(isOpen);

  const isFileImageType = useMemo(() => uploadedFile?.type.includes('image'), [uploadedFile]);
  const isFilePdfType = useMemo(() => uploadedFile?.type === 'application/pdf', [uploadedFile]);
  const fileUrl = useMemo(() => (uploadedFile ? URL.createObjectURL(uploadedFile) : ''), [uploadedFile]);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <FileUploadContent
        onInteractOutside={(event) => event.preventDefault()}
        className="max-h-[720px] max-w-[720px] overflow-y-auto"
        uploadheadercomponent={(
          <UploadFilePreviewHeader
            uploadedFile={uploadedFile}
            selectedFileId={selectedFileId}
            setSelectedFileId={setSelectedFileId}
            setUploadedFile={setUploadedFile}
          />
        )}
      >
        <DialogTitle className="sr-only">Preview</DialogTitle>
        <DialogDescription className="sr-only">Preview of the uploaded file</DialogDescription>
        {isFileImageType && (
          <img src={fileUrl} className="block aspect-auto h-auto w-full rounded-md object-contain" alt="preview" />
        )}
        {isFilePdfType && (
          <div className="flex items-center gap-4 p-6">
            <PurpleIcon name="pdf-file" className="size-16" />
            <Heading variant="size-18" type="heading-500">
              {uploadedFile?.name ?? 'Unknown file'}
            </Heading>
          </div>
        )}
      </FileUploadContent>
    </Dialog>
  );
};
