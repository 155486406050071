import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ActivityType, type TActivityInfo } from '@purple/shared-types';
import { Form, FormControl, FormField, FormItem, FormMessage, Text, Textarea } from '@purple/ui';
import { useCurrentUser } from '~/hooks/redux';
import { useUpdateActivity, useUpdateRecurringActivity } from '~/queries';
import { ActivitySectionHeader } from './ActivitySectionHeader';
import type React from 'react';

const descriptionSchema = z.object({
  description: z.string().trim(),
});

type TActivityDescriptionSectionProperties = {
  activity: TActivityInfo;
};

export const ActivityDescriptionSection: React.FC<TActivityDescriptionSectionProperties> = (props) => {
  const { activity } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateActivity, isPending } = useUpdateActivity();
  const { mutate: updateRecurringActivity, isPending: isRecurringPending } = useUpdateRecurringActivity();

  const { user } = useCurrentUser();
  const allowedToEdit = useMemo(() => user.id === activity.details.created_by.id, [user, activity]);

  const defaultValues = useMemo(
    () => ({
      description:
        activity.details.community_activity_type.name === ActivityType.CRISIS_CALL_TRACKER
          ? activity.details.description_of_crisis ?? ''
          : activity.description ?? '',
    }),
    [activity],
  );

  const form = useForm<z.infer<typeof descriptionSchema>>({
    resolver: zodResolver(descriptionSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDescriptionClickHandler = (formData: z.infer<typeof descriptionSchema>, recurringId?: string) => {
    const updateCallback = recurringId ? updateRecurringActivity : updateActivity;
    const entityId = recurringId ?? activity.id;

    updateCallback(
      {
        id: entityId,
        ...(activity.details.community_activity_type.name !== ActivityType.CRISIS_CALL_TRACKER && {
          description: formData.description,
        }),
        name: activity.name,
        details: {
          school: activity.details.school.id,
          ...(activity.details.community_activity_type.name === ActivityType.CRISIS_CALL_TRACKER && {
            description_of_crisis: formData.description,
          }),
          ...(activity.details.assigned_to && { assigned_to: activity.details.assigned_to.id }),
          ...(activity.details.service_categories && {
            service_categories: activity.details.service_categories.map((category) => category.id),
          }),
          type: activity.details.community_activity_type.name,
          duration: activity.details.duration,
        },
        ...(recurringId !== null && {
          weekly_interval: activity.details.recurring_group?.weekly_interval,
          days_of_week: activity.details.recurring_group?.days_of_week,
          start_date_and_time: activity.details.recurring_group?.start_date_and_time,
          end_date_and_time: activity.details.recurring_group?.end_date_and_time,
        }),
      },
      {
        onSuccess: () => {
          setIsEditing(false);
          form.reset(formData);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <ActivitySectionHeader
        title="Activity Description"
        editing={isEditing}
        loading={isPending}
        recurringLoading={isRecurringPending}
        allowRecurring={activity.details.recurring_group !== null}
        disableEditing={!allowedToEdit}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit((data) => saveDescriptionClickHandler(data))}
        onRecurringSave={form.handleSubmit((data) => saveDescriptionClickHandler(data, activity.details.recurring_group?.id))}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-1">
              <FormField
                control={form.control}
                name="description"
                render={({ field, fieldState }) => (
                  <FormItem className="col-span-2">
                    <FormControl>
                      <Textarea
                        {...field}
                        isError={!!fieldState.error}
                        placeholder="Enter description here"
                        responsiveHeight
                        className="min-h-[80px] resize-none"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <Text className="text-balance font-primary text-base font-normal text-grey-600">
              {(activity.details.community_activity_type.name === ActivityType.CRISIS_CALL_TRACKER
                ? activity.details.description_of_crisis
                : activity.description) ?? 'No description provided'}
            </Text>
          )}
    </div>
  );
};
