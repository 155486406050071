import { useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActivityType, ReminderWeeklyInterval } from '@purple/shared-types';
import {
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Message,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  Textarea,
} from '@purple/ui';
import { ReadableActivityType } from '~/constants/create-activity';
import { useAppSelector, useCreateActivity } from '~/hooks/redux';
import {
  useActivitiesTypes,
  useActivityChoices,
  useCreateNotRecurringActivity,
  useCreateRecurringActivity,
} from '~/queries';
import { userSelector } from '~/store/features/user';
import { ActivityContainerModal } from '../ActivityContainerModal';
import { EnableRecurring } from '../EnableRecurringFields';
import { UploadFilesField } from '../UploadFilesField';
import { nonCounselingActivitySchema } from './schema';
import type { z } from 'zod';

type TNonCounselingRelatedActivityDutyProperties = {
  onSuccessfulActivity?: () => void;
};

export const NonCounselingRelatedActivityDutyModal = ({
  onSuccessfulActivity,
}: TNonCounselingRelatedActivityDutyProperties) => {
  const formId = useId();
  const { toggleActivityModal, isNonCounselingRelatedActivityDutyModalOpen } = useCreateActivity();

  const { schools } = useAppSelector(userSelector);

  const { mutate: createActivity, isPending: isCreatingActivity } = useCreateNotRecurringActivity();
  const { mutate: createRecurringActivity, isPending: isCreatingRecurringActivity } = useCreateRecurringActivity();

  const [uploadedFiles, setUploadedFiles] = useState<File[] | null>(null);
  const [isFileError, setIsFileError] = useState<boolean>(false);

  const schoolsOptions = useMemo(() => {
    return schools.map((school) => ({
      label: school.name,
      value: school.id,
    }));
  }, [schools]);

  const { data: activitiesTypes } = useActivitiesTypes();

  const community_activity_type_id = useMemo(() => {
    return activitiesTypes?.find((item) => item.name === ActivityType.NON_COUNSELING_RELATED)?.id;
  }, [activitiesTypes]);

  const { tagsOptions, typeOptions } = useActivityChoices({
    enabled: isNonCounselingRelatedActivityDutyModalOpen,
    community_activity_type: ActivityType.NON_COUNSELING_RELATED,
  });

  const defaultValues = useMemo(() => {
    const [defaultSchool] = schools;

    return {
      school: schools.length === 1 ? defaultSchool?.id : '',
      tags: [],
      name: '',
      type: '',
      start_date_and_time: new Date(),
      duration: undefined,
      description: '',
      enable_recurring: false,
      end_date_and_time: new Date(),
      weekly_interval: ReminderWeeklyInterval.EVERY,
      days_of_week: [],
    };
  }, [schools]);

  const form = useForm<z.infer<typeof nonCounselingActivitySchema>>({
    resolver: zodResolver(nonCounselingActivitySchema),
    mode: 'onChange',
    defaultValues,
  });

  const formEnableRecurring: boolean = form.watch('enable_recurring', false);

  const resetFormHandler = () => {
    toggleActivityModal(null);
    onSuccessfulActivity?.();
    form.reset(defaultValues);
  };

  const uploadFilesHandler = (files: File[] | null) => {
    setUploadedFiles(files);
    setIsFileError(false);
  };

  const uploadFilesErrorHandler = () => {
    setIsFileError(true);
  };

  const submitActivityHandler = (data: z.infer<typeof nonCounselingActivitySchema>) => {
    if (!community_activity_type_id) return;

    const isRecurring = data.enable_recurring;

    const selectedTags = tagsOptions
      .filter((item) => data.tags.includes(item.value))
      .map((item) => ({ name: item.label, color: item.color }));

    if (isRecurring) {
      const body = {
        community_activity_type: community_activity_type_id,
        name: data.name,
        type: data.type,
        school: data.school,
        start_date_and_time: data.start_date_and_time.toISOString(),
        ...(selectedTags.length > 0 && { tags: selectedTags }),
        duration: data.duration,
        ...(data.description && { description: data.description }),
        ...(uploadedFiles && { attachments: uploadedFiles }),
        ...(data.end_date_and_time && { end_date_and_time: data.end_date_and_time.toISOString() }),
        ...(data?.days_of_week && data?.days_of_week?.length > 0 && { days_of_week: data?.days_of_week }),
        ...(data.weekly_interval && { weekly_interval: data.weekly_interval }),
      };

      createRecurringActivity(body, {
        onSuccess: () => {
          resetFormHandler();
        },
      });
    } else {
      const body = {
        community_activity_type: community_activity_type_id,
        name: data.name,
        type: data.type,
        school: data.school,
        date_and_time: data.start_date_and_time.toISOString(),
        ...(selectedTags.length > 0 && { tags: selectedTags }),
        duration: data.duration,
        ...(data.description && { description: data.description }),
        ...(uploadedFiles && { attachments: uploadedFiles }),
      };

      createActivity(body, {
        onSuccess: () => {
          resetFormHandler();
        },
      });
    }
  };

  return (
    <ActivityContainerModal
      title={ReadableActivityType[ActivityType.NON_COUNSELING_RELATED]}
      submitButtonLabel="Create"
      formId={formId}
      activityType={ActivityType.NON_COUNSELING_RELATED}
      isLoading={isCreatingActivity || isCreatingRecurringActivity}
      onClose={resetFormHandler}
    >
      <Form
        providerProps={form}
        id={formId}
        className="grid w-full grid-cols-2 gap-4"
        onSubmit={form.handleSubmit(submitActivityHandler)}
      >
        {isFileError && (
          <div className="col-span-2 flex">
            <Message variant="error" onClose={() => setIsFileError(false)}>
              Invalid file format or size!
            </Message>
          </div>
        )}
        <FormField
          control={form.control}
          name="school"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>School</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select school"
                    selectedLabel={schoolsOptions.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search school..." emptyContent="School not found.">
                  {schoolsOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="tags"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Tags</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!fieldState.error}
                  selectedOptions={tagsOptions.filter((tag) => field.value.includes(tag.value))}
                  placeholder="Select tags"
                  modalPopover
                  onOptionChange={field.onChange}
                >
                  {tagsOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Activity Name</FormLabel>
              <FormControl>
                <Input {...field} isError={!!form.formState.errors.name} placeholder="Enter name here" type="text" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="type"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Type</FormLabel>
              <ComboBox modal>
                <FormControl>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select type"
                    selectedLabel={typeOptions.find((option) => option.value === field.value)?.label}
                  />
                </FormControl>
                <ComboBoxContent searchPlaceholder="Search type..." emptyContent="Type not found.">
                  {typeOptions.map(({ label, value }) => (
                    <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                      {label}
                    </ComboBoxItem>
                  ))}
                </ComboBoxContent>
              </ComboBox>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="start_date_and_time"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Date & Time</FormLabel>
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  defaultMonth={field.value}
                  selected={field.value}
                  disabled={{ before: new Date() }}
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="duration"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Duration (In Minutes)</FormLabel>
              <FormControl>
                <NumberInput
                  {...field}
                  type="number"
                  isError={!!fieldState.error}
                  placeholder="Enter duration"
                  min={0}
                  changeOnWheel
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!form.formState.errors.description}
                  placeholder="Enter description text here"
                  responsiveHeight
                  className="min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <EnableRecurring<z.infer<typeof nonCounselingActivitySchema>>
          control={form.control}
          formEnableRecurring={formEnableRecurring}
          enableRecurringName="enable_recurring"
          endDateAndTimeName="end_date_and_time"
          weeklyIntervalName="weekly_interval"
          daysOfWeekName="days_of_week"
        />
        <UploadFilesField onFileUpload={uploadFilesHandler} onError={uploadFilesErrorHandler} />
      </Form>
    </ActivityContainerModal>
  );
};
