import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { PAGE_VIEW_QUERIES, type TDashboardPriorityList } from '@purple/shared-types';
import { Heading, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@purple/ui';
import { EmptyDataPlaceholder } from '~/components';
import { AppRoutes } from '~/constants/routes/routes';
import { MyPriorityListSkeleton } from './MyPriorityListSkeleton';
import type React from 'react';

type TMyPriorityListProperties = {
  /**
   * User priority list data for the selected period.
   */
  data?: TDashboardPriorityList[];
  /**
   * Indicates if the data is loading.
   * @default false
   */
  isLoading?: boolean;
};

export const MyPriorityList: React.FC<TMyPriorityListProperties> = (props) => {
  const { data, isLoading = false } = props;

  if (isLoading) {
    return <MyPriorityListSkeleton />;
  }

  return (
    <div className="col-span-2 flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white pt-6 shadow-custom-medium">
      <Heading tag="h3" variant="size-18" type="heading-600" className="px-4 text-base text-grey-title">
        My Priority Lists
      </Heading>
      {data && data.length > 0
        ? (
            <Table>
              <TableHeader>
                <TableRow className="border-grey-300">
                  <TableHead className="w-1/3 px-4 py-3 text-left text-xs font-semibold uppercase text-grey-600">
                    Name
                  </TableHead>
                  <TableHead className="w-1/3 px-4 py-3 text-left text-xs font-semibold uppercase text-grey-600">
                    Type
                  </TableHead>
                  <TableHead className="w-1/3 px-4 py-3 text-left text-xs font-semibold uppercase text-grey-600">
                    Date created
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map(({ id, name, created_at, reason }) => (
                  <TableRow key={id} className="border-grey-200 last-of-type:border-0">
                    <TableCell className="px-4 py-3 text-left text-sm font-normal text-grey-950">
                      <Link
                        to={AppRoutes.App.Visualizer.Root.makePath('my-priority-lists', PAGE_VIEW_QUERIES.DETAIL_VIEW, id)}
                        className="inline-block cursor-pointer font-medium text-brand-blue-700 transition-colors duration-200 hover:text-brand-blue-800 focus:text-brand-blue-800 focus-visible:text-brand-blue-800 focus-visible:outline-none active:text-brand-blue-500 active:transition-none"
                      >
                        <span className="line-clamp-1">{name}</span>
                      </Link>
                    </TableCell>
                    <TableCell className="px-4 py-3 text-left text-sm font-normal text-grey-950">
                      <span className="line-clamp-1">{reason}</span>
                    </TableCell>
                    <TableCell className="px-4 py-3 text-left text-sm font-normal text-grey-950">
                      <span className="line-clamp-1">{format(created_at, 'PPP')}</span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
        : (
            <EmptyDataPlaceholder
              title="No priority list items"
              message="Create a priority list to keep track of your most important tasks."
            />
          )}
    </div>
  );
};
