import { PurpleIcon } from '@purple/icons';
import { cn } from '@purple/ui';

type TEditableAreaProperties = React.PropsWithChildren<{
  highlight: boolean;
  className?: string;
}>;

const EditableArea = ({ highlight, children, className = '' }: TEditableAreaProperties) => {
  return (
    <div
      className={cn(
        'flex items-center justify-between gap-2 rounded-lg px-[12px] py-[6px] text-transparent',
        {
          'hover:cursor-pointer hover:bg-grey-100 hover:text-grey-600': highlight,
        },
        className,
      )}
    >
      {children}
      {highlight && <PurpleIcon name="pencil" className="size-4 min-h-4 min-w-4" />}
    </div>
  );
};

export { EditableArea };
