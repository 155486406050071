import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  login,
  logout,
  resetPassword,
  resetPasswordConfirm,
  sendEmailConfirmation,
  sendEmailDecline,
} from '@purple/shared-services';
import { AUTH_QUERY_KEYS, LocalStorageKey, USER_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { AppRoutes } from '~/constants/routes/routes';
import { useAppDispatch } from '~/hooks/redux';
import { resetApp } from '~/store';
import { setAuthenticated, setUser } from '~/store/features/user';
import { showErrorToast, showSuccessToast } from '../../shared/lib';
import type { AxiosError, LoginRequestBody, LoginResponseBody, TResetPasswordConfirmRequestObject } from '@purple/shared-services';

export const useResetPasswordMutation = () => {
  return useMutation({
    mutationKey: [AUTH_QUERY_KEYS.RESET_PASSWORD],
    mutationFn: resetPassword,
    onError: () => {
      showErrorToast('System message', 'Could not reset password. Check the provided information and try again');
    },
  });
};

export const useResetPasswordConfirmMutation = () => {
  const navigate = useNavigate();
  return useMutation<void, AxiosError<{
    token: string[];
  }>, TResetPasswordConfirmRequestObject>({
    mutationKey: [AUTH_QUERY_KEYS.CONFIRM_RESET_PASSWORD],
    mutationFn: resetPasswordConfirm,
    onSuccess: () => {
      navigate(AppRoutes.Auth.SignIn.Root.path, { replace: true });
      showSuccessToast('System message', 'New password set successfully. Please login with new credentials');
    },
    onError: (error) => {
      if (error.response && error.response.status === 400 && Boolean(error.response.data.token.length)) {
        const message = 'The password has already been reset via this link, or the link has expired.';
        showErrorToast('System message', message);
      } else {
        showErrorToast('System message', 'Could not reset password. Check the provided information and try again');
      }
    },
  });
};

export const useSendChangeEmailMutation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationKey: [AUTH_QUERY_KEYS.SEND_CHANGE_EMAIL],
    mutationFn: sendEmailConfirmation,
    onSuccess: () => {
      showSuccessToast('System message', 'New email is confirmed successfully. Please login with new credentials');

      // Reset the hole app state
      dispatch(resetApp());
      // Full clear the local storage
      localStorage.clear();
      // reset the query client to prevent Tanstack Query from caching queries from the previous user
      queryClient.clear();

      navigate(AppRoutes.Auth.SignIn.Root.makePath('email_confirmed'), { replace: true });
    },
    onError: () => {
      showErrorToast('System message', 'Could not confirm new email. Check the provided information and try again');
      navigate(AppRoutes.App.Home.Root.path, { replace: true });
    },
  });
};

export const useDeclineEmailMutation = () => {
  return useMutation({
    mutationKey: [AUTH_QUERY_KEYS.DECLINE_CHANGE_EMAIL],
    mutationFn: sendEmailDecline,
    onSuccess: () => {
      showSuccessToast('System message', 'Email changing is declined successfully. Please login with new credentials');
    },
  });
};

export const useLoginMutation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return useMutation<LoginResponseBody, AxiosError<{ detail?: string }>, LoginRequestBody>({
    mutationKey: [AUTH_QUERY_KEYS.LOGIN],
    mutationFn: login,
    onSuccess: (responseData) => {
      localStorage.setItem(LocalStorageKey.Auth.Token.AccessToken, responseData.access);
      localStorage.setItem(LocalStorageKey.Auth.Token.RefreshToken, responseData.refresh);

      dispatch(setUser(responseData.user));
      dispatch(setAuthenticated(true));
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEYS.CURRENT] });

      navigate(AppRoutes.App.Home.Root.path, { replace: true });
    },
    onError: () => showErrorToast('Login failed', 'Invalid email or password.'),
  });
};

export const useLogoutMutation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: [AUTH_QUERY_KEYS.LOGOUT],
    mutationFn: logout,
    onSuccess: () => {
      // Reset the hole app state
      dispatch(resetApp());
      // Full clear the local storage
      localStorage.clear();
      // reset the query client to prevent Tanstack Query from caching queries from the previous user
      queryClient.clear();

      navigate(AppRoutes.Auth.SignIn.Root.path, { replace: true });
      showSuccessToast('System message', 'You have been logged out successfully.');
    },
    onError: () => showErrorToast('Logout failed', 'Something went wrong, try again later.'),
  });
};
