import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading } from '@purple/ui';
import type React from 'react';
import type { PurpleIconType } from '@purple/icons';

type TGroupActionSectionHeaderProperties = {
  /**
   * The title of the section.
   */
  title?: string | null;
  /**
   * Whether the section is in editing mode.
   * @default false
   */
  editing?: boolean;
  /**
   * Whether the section is in loading state.
   * @default false
   */
  loading?: boolean;
  /**
   * The label for the edit button.
   * @default 'Edit'
   */
  editButtonLabel?: string;
  /**
   * Name of the icon for the edit button.
   * @default 'pencil'
   */
  editButtonIcon?: PurpleIconType;
  /**
   * Indicates whether the editing is disabled.
   * @default false
   */
  disableEditing?: boolean;
  /**
   * Callback when the edit button is clicked.
   */
  onEdit?: () => void;
  /**
   * Callback when the cancel button is clicked.
   */
  onCancel?: () => void;
  /**
   * Callback when the save button is clicked.
   */
  onSave?: () => void;
};

export const GroupActionSectionHeader: React.FC<TGroupActionSectionHeaderProperties> = (props) => {
  const {
    title = null,
    editing = false,
    loading = false,
    disableEditing = false,
    editButtonLabel = 'Edit',
    editButtonIcon = 'pencil',
    onEdit,
    onCancel,
    onSave,
  } = props;

  const editingControls = useMemo(
    () =>
      editing
        ? (
            <div className="ml-auto flex flex-row items-center gap-3">
              <Button type="button" variant="secondary" size="small" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="button" variant="primary" size="small" isLoading={loading} disabled={loading} onClick={onSave}>
                Save
              </Button>
            </div>
          )
        : (
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="ml-auto"
              iconLeft={<PurpleIcon name={editButtonIcon} className="size-4 shrink-0" />}
              onClick={onEdit}
            >
              {editButtonLabel}
            </Button>
          ),
    [editing, loading, onCancel, onSave, onEdit, editButtonIcon, editButtonLabel],
  );

  return (
    <div className="flex flex-row items-center justify-between gap-2">
      {title && (
        <Heading tag="h3" className="text-base font-semibold text-grey-950">
          {title}
        </Heading>
      )}
      {!disableEditing && editingControls}
    </div>
  );
};
