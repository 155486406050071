import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { SAF_STATUS, type TSafDetailsDto } from '@purple/shared-types';
import { SAF_QUERY_KEYS, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Badge, Button, cn, Heading, Text } from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';

export const SafSideBarHeader = () => {
  const { safId } = useParams();
  const queryClient = useQueryClient();

  const { openModal: openSafTransferModal } = useModal(ModalType.SAF_TRANSFER);
  const { openModal: openSafPriorityModal } = useModal(ModalType.SAF_PRIORITY);
  const { openModal: openSafNewEmail } = useModal(ModalType.SAF_NEW_EMAIL);

  const safDetailsData = queryClient.getQueryData<TSafDetailsDto>([SAF_QUERY_KEYS.GET_SAF_DETAILS, safId]);

  const { title, saf_number, status, priority, description_translated, saf_submission_details } = safDetailsData || {};

  const isSubmittedAnonymously = useMemo(
    () => saf_submission_details?.is_submitted_anonymously,
    [saf_submission_details?.is_submitted_anonymously],
  );

  const hasSubmittedEmail = useMemo(() => Boolean(saf_submission_details && saf_submission_details.submitter_email && saf_submission_details.submitter_email.length > 0), [saf_submission_details]);

  const hasSafClosedStatus = useMemo(() => Boolean(status === SAF_STATUS.CLOSED), [status]);
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <Heading variant="size-18" type="heading-600" className="line-clamp-2 leading-6">
            {title ?? '—'}
          </Heading>
          <Text variant="size-14" type="body-400" className="text-grey-600">
            SAF Number:
            <span className="font-semibold text-grey-950">
              {' '}
              {saf_number ?? '—'}
            </span>
          </Text>
        </div>
        <div className="flex items-center gap-6">
          <div className="flex items-center gap-1">
            <Text variant="size-14" type="body-400" className="text-grey-600">
              Status:
            </Text>
            {status
              ? (
                  <Badge
                    label={snakeToCamelWithSpaces(status)}
                    className={cn('px-2 py-0.5', {
                      'bg-brand-blue-100 text-brand-blue-700': status === SAF_STATUS.NEW,
                      'bg-success-bg text-success-main': status === SAF_STATUS.PRIORITIZED,
                      'bg-warning-bg text-warning-main': status === SAF_STATUS.ON_HOLD,
                      'bg-grey-100 text-grey-600': status === SAF_STATUS.CLOSED,
                    })}
                  />
                )
              : (
                  <Text variant="size-14" type="body-500" className="text-grey-960">
                    —
                  </Text>
                )}
          </div>
          <div className="flex items-center gap-1">
            <Text variant="size-14" type="body-400" className="text-grey-600">
              Priority:
              {' '}
              <span className="font-semibold capitalize text-grey-950">{priority ?? 'None'}</span>
            </Text>
            {priority
              ? (
                  <PurpleIcon
                    name="pencil"
                    className="size-4 cursor-pointer text-grey-600"
                    onClick={openSafPriorityModal}
                  />
                )
              : (
                  <PurpleIcon name="exclamation-circle" className="size-4 text-error-main" />
                )}
          </div>
        </div>
        <div className="flex items-center gap-4">
          {!isSubmittedAnonymously && hasSubmittedEmail && <Button onClick={openSafNewEmail}>Email Submitter</Button>}
          {!hasSafClosedStatus && (
            <Button variant="secondary" onClick={openSafTransferModal}>
              Transfer SAF
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-0.5">
        <Text variant="size-16" type="body-600">
          Description
        </Text>
        <Text variant="size-16" type="body-400" className="text-grey-600">
          {description_translated ?? '—'}
        </Text>
      </div>
    </div>
  );
};
