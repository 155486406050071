import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { getInitialsFromName } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  Separator,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useUsersSearch } from '~/queries';
import { sharePriorityListSchema } from './schema';
import type * as z from 'zod';
import type { TPriorityListSharedUser } from '@purple/shared-types';

const SEARCH_DEBOUNCE_DELAY = 500;

type TSharePriorityListModalProperties = {
  defaultShareWith: TPriorityListSharedUser[];
  onSubmit: (sharedUsers: string[]) => void;
  isLoading?: boolean;
};

export const SharePriorityListModal = ({
  defaultShareWith,
  onSubmit,
  isLoading = false,
}: TSharePriorityListModalProperties) => {
  const { isOpen, toggleModal, closeModal } = useModal('share-priority-list');

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { data: sharedUsers, isFetching: isUserFetching } = useUsersSearch({
    enabled: isOpen,
    search: debouncedSearchValue,
  });

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, SEARCH_DEBOUNCE_DELAY);

  const multiSelectOptions = useMemo(
    () =>
      sharedUsers?.results.map((user) => ({
        ...user,
        label: user.full_name ?? user.email,
        value: user.id,
      })) ?? [],
    [sharedUsers],
  );

  const form = useForm<z.infer<typeof sharePriorityListSchema>>({
    resolver: zodResolver(sharePriorityListSchema),
    mode: 'onChange',
    defaultValues: {
      users: [],
    },
  });

  const formUsers = form.watch('users');

  useEffect(() => {
    form.reset({
      users: defaultShareWith.map((user) => user.id),
    });
  }, [defaultShareWith, form]);

  const shareListSubmitHandler = (data: z.infer<typeof sharePriorityListSchema>) => {
    onSubmit(data.users);
  };

  const canSubmit = useMemo(() => {
    function arraysAreDifferent(array1: string[], array2: string[]): boolean {
      return (
        array1.length !== array2.length
        || array1.some((item) => !array2.includes(item))
        || array2.some((item) => !array1.includes(item))
      );
    }

    const defaultUsersIds = defaultShareWith.map((user) => user.id);
    const usersWereChanged = arraysAreDifferent(formUsers, defaultUsersIds);

    return !isLoading && usersWereChanged;
  }, [defaultShareWith, formUsers, isLoading]);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <Form providerProps={form} onSubmit={form.handleSubmit(shareListSubmitHandler)}>
          <DialogHeader className="flex-row items-center justify-between">
            <DialogTitle>Share Priority List</DialogTitle>
            <DialogDescription className="sr-only">Select users to share priority list with</DialogDescription>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          <div className="flex w-full p-6">
            <FormField
              control={form.control}
              name="users"
              render={({ field, fieldState }) => (
                <FormItem className="w-full">
                  <FormLabel required>Share With</FormLabel>
                  <FormControl>
                    <MultiSelect
                      {...field}
                      isError={!!fieldState.error}
                      selectedOptions={multiSelectOptions.filter((tag) => field.value.includes(tag.value))}
                      showSearch
                      loading={isUserFetching}
                      shouldFilter={false}
                      searchPlaceholder="Search by user name or email"
                      placeholder="Select users"
                      modalPopover
                      onOptionChange={field.onChange}
                      onSearchChange={debouncedSearch}
                    >
                      {multiSelectOptions.map((option) => (
                        <MultiSelectItem
                          key={option.value}
                          value={option.value}
                          option={option}
                          isSelected={field.value.includes(option.value)}
                          customContent
                        >
                          <>
                            {field.value.includes(option.value) && (
                              <PurpleIcon
                                name="check"
                                className="absolute left-4 top-1/2 size-4 -translate-y-1/2 text-brand-blue-700"
                              />
                            )}
                            <div className="flex items-center gap-2">
                              <Avatar size={32}>
                                <AvatarImage src={option.avatar ?? undefined} alt={option.full_name ?? option.email} />
                                <AvatarFallback className="bg-grey-200">
                                  {getInitialsFromName(option.full_name ?? '- -')}
                                </AvatarFallback>
                              </Avatar>
                              <div className="flex flex-col gap-1">
                                <strong className="line-clamp-1 text-xs font-medium text-grey-950">
                                  {option.full_name ?? option.email}
                                </strong>
                                <span className="line-clamp-1 text-xs text-grey-600">{option.email}</span>
                              </div>
                            </div>
                          </>
                        </MultiSelectItem>
                      ))}
                    </MultiSelect>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Separator />
          <DialogFooter>
            <Button variant="tertiary" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" disabled={!canSubmit} isLoading={isLoading}>
              Submit
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
