import { Text } from '@purple/ui';
import type { FC } from 'react';
import type { ColorRanges } from '@purple/shared-types';

type TLegendComponentProperties = {
  colorRange: ColorRanges;
};

const LegendComponent: FC<TLegendComponentProperties> = ({ colorRange }) => {
  return (
    <div className="flex gap-4">
      <div className="flex items-center gap-1">
        <div className="size-2 rounded-[50%] bg-other-green" />
        <Text type="body-500" variant="size-12" className="text-grey-600">
          Green (
          {colorRange.green[0]}
          -
          {colorRange.green[1]}
          )
        </Text>
      </div>
      <div className="flex items-center gap-1">
        <div className="size-2 rounded-[50%] bg-brand-yellow" />
        <Text type="body-500" variant="size-12" className="text-grey-600">
          Yellow (
          {colorRange.yellow[0]}
          -
          {colorRange.yellow[1]}
          )
        </Text>
      </div>
      <div className="flex items-center gap-1">
        <div className="size-2 rounded-[50%] bg-other-rose-2" />
        <Text type="body-500" variant="size-12" className="text-grey-600">
          Red (
          {colorRange.red[0]}
          +)
        </Text>
      </div>
    </div>
  );
};

export { LegendComponent };
