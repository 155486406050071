import { useMutation } from '@tanstack/react-query';
import { createAction } from '@purple/shared-services';
import { ACTIONS_QUERY_KEYS } from '@purple/shared-utils';
import { queryClient } from '~/constants/query-client';
import { showErrorToast, showSuccessToast } from '~/shared/lib';
import type { AxiosError } from '@purple/shared-services';
import type { TAction, TActionBody } from '@purple/shared-types';

export const useCreateAction = () => {
  return useMutation<TAction | TAction[], AxiosError<{ saf: string[] }>, TActionBody>({
    mutationKey: [ACTIONS_QUERY_KEYS.CREATE_ACTION],
    mutationFn: createAction,
    onSuccess: () => {
      showSuccessToast('System message', 'The action was created successfully');
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_SCHOOL_LIST] });
    },
    onError: (error) => {
      if (error.response?.data?.saf) {
        showErrorToast('System message', error.response.data.saf.join(', '));
        return;
      }
      showErrorToast('System message', 'Could not create an action. Check the provided information and try again');
    },
  });
};
