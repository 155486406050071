import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { cn } from '@purple/ui';
import { AppRoutes } from '~/constants/routes/routes';
import { StudentName } from './StudentName';
import { StudentSubpopulations } from './StudentSubpopulations';
import type { TSafListManagerDto } from '@purple/shared-types';

type TStudentNameColumnProperties = {
  data: TSafListManagerDto;
};

const StudentNameColumn = ({ data }: TStudentNameColumnProperties) => {
  const { submitted_student_first_name, submitted_student_last_name, student } = data;

  const isStudentLinked = useMemo(() => Boolean(student), [student]);
  const isSubpopulationExist = useMemo(() => Boolean(student?.subpop.length), [student]);

  return (
    <div className="flex items-center gap-2">
      <div
        className={cn('min-h-2 min-w-2 rounded-full', {
          'bg-error-main': !isStudentLinked,
          'bg-transparent': isStudentLinked,
        })}
      />
      <StudentName
        submitted_student_first_name={submitted_student_first_name}
        submitted_student_last_name={submitted_student_last_name}
        student={student}
      />
      {isStudentLinked && (
        <Link to={AppRoutes.App.Students.StudentDetails.Root.makePath(student?.id, 'dashboard')} target="_blank">
          <PurpleIcon name="link" className="size-4 min-h-4 min-w-4 text-grey-400" />
        </Link>
      )}
      {isSubpopulationExist && <StudentSubpopulations subpops={student?.subpop || []} />}
    </div>
  );
};

export { StudentNameColumn };
