import { PurpleIcon } from '@purple/icons';
import { Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { ISubPopulation } from '@purple/shared-types';

type TStudentSubpopulationsProperties = {
  subpops: ISubPopulation[];
};

const StudentSubpopulations = ({ subpops }: TStudentSubpopulationsProperties) => {
  return (
    <Tooltip delayDuration={300}>
      <TooltipTrigger>
        <PurpleIcon name="exclamation" className="size-4 min-h-4 min-w-4 text-brand-blue-900" />
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          align="start"
          className="w-[200px] border border-grey-200 bg-white text-center"
          withArrow={false}
        >
          <div className="flex w-full flex-col justify-start gap-1">
            {subpops.map((subpop) => (
              <Text key={subpop.id} variant="size-12" type="body-500" className="px-4 text-start text-grey-950">
                {subpop.name}
              </Text>
            ))}
          </div>
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};

export { StudentSubpopulations };
