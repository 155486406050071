import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ReadableSafSubmitterType, type TSafDetailsDto } from '@purple/shared-types';
import { cutGradeName, getNationalFormattedPhoneNumber, SAF_QUERY_KEYS } from '@purple/shared-utils';
import { DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';

type TSafSubmissionDetailsProperties = {
  safId?: string | null;
};

export const SafSubmissionDetails = ({ safId }: TSafSubmissionDetailsProperties) => {
  const queryClient = useQueryClient();

  const safDetailsData = queryClient.getQueryData<TSafDetailsDto>([SAF_QUERY_KEYS.GET_SAF_DETAILS, safId]);

  const { saf_submission_details } = safDetailsData || {};

  const submittedStudentFullName = useMemo(() => {
    if (!saf_submission_details?.submitted_student_first_name && !saf_submission_details?.submitted_student_last_name) {
      return '—';
    }

    return `${saf_submission_details?.submitted_student_first_name} ${saf_submission_details?.submitted_student_last_name}`;
  }, [saf_submission_details?.submitted_student_first_name, saf_submission_details?.submitted_student_last_name]);

  return (
    <DescriptionList>
      <DescriptionItem>
        <DescriptionTerm>Submitted Student Name</DescriptionTerm>
        <DescriptionDetails className="capitalize">{submittedStudentFullName}</DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>Submitted Student Grade</DescriptionTerm>
        <DescriptionDetails>
          {saf_submission_details && saf_submission_details.submitted_student_grade
            ? cutGradeName(saf_submission_details.submitted_student_grade)
            : '—'}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>Submitter Type</DescriptionTerm>
        <DescriptionDetails>{saf_submission_details && saf_submission_details.submitter_type ? ReadableSafSubmitterType[saf_submission_details.submitter_type] : '—'}</DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>Submitted Student School</DescriptionTerm>
        <DescriptionDetails>{saf_submission_details?.submitted_student_school ?? '—'}</DescriptionDetails>
      </DescriptionItem>
      {!saf_submission_details?.is_submitted_anonymously && (
        <>
          <DescriptionItem>
            <DescriptionTerm>Submitter Name</DescriptionTerm>
            <DescriptionDetails className="capitalize">
              {saf_submission_details?.submitter_name ?? '—'}
            </DescriptionDetails>
          </DescriptionItem>
          <DescriptionItem>
            <DescriptionTerm>Submitter Phone</DescriptionTerm>
            <DescriptionDetails>
              {getNationalFormattedPhoneNumber({ phoneNumber: saf_submission_details?.submitter_phone })}
            </DescriptionDetails>
          </DescriptionItem>
          <DescriptionItem>
            <DescriptionTerm>Submitter Email</DescriptionTerm>
            <DescriptionDetails className="line-clamp-1 flex items-center gap-2">
              {saf_submission_details?.submitter_email ?? '—'}
            </DescriptionDetails>
          </DescriptionItem>
        </>
      )}
    </DescriptionList>
  );
};
