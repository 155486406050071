import { useMemo } from 'react';
import { useSearch, useSort } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  SearchInput,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { NOTES_SORT_SELECT_OPTIONS } from '../../constants';
import { GroupActionAddNoteDialog } from './GroupActionAddNoteDialog';
import type React from 'react';
import type { TGroupActionDetails } from '@purple/shared-types';

type TGroupActionNotesHeaderProperties = {
  groupAction: TGroupActionDetails;
};

export const GroupActionNotesHeader: React.FC<TGroupActionNotesHeaderProperties> = (props) => {
  const { groupAction } = props;

  const { sort, onSortChange, onSortClear } = useSort();
  const { search, onSearchChange, onClearSearch } = useSearch();
  const { openModal: openAddNoteModal } = useModal(ModalType.GROUP_ACTION_ADD_NOTE);

  const hasSortValue = useMemo(() => Boolean(sort), [sort]);

  return (
    <div className="flex w-full items-center justify-between gap-4">
      <div className="flex w-full items-center justify-start gap-4">
        <RadixSelect value={sort} defaultValue={sort} onValueChange={onSortChange}>
          <RadixSelectTrigger
            hasClearButton={hasSortValue}
            onClearCallback={onSortClear}
            triggerContainerClassName="w-[200px]"
          >
            <RadixSelectValue placeholder="Sort" />
          </RadixSelectTrigger>
          <RadixSelectContent className="min-w-max">
            {NOTES_SORT_SELECT_OPTIONS.map((option) => (
              <RadixSelectItem key={option.value} value={option.value} className="cursor-pointer">
                {option.label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        <SearchInput
          value={search}
          onChange={onSearchChange}
          onClear={onClearSearch}
          placeholder="Search by title"
          className="max-w-[300px]"
        />
      </div>
      <Button type="button" iconLeft={<PurpleIcon name="plus" />} onClick={openAddNoteModal}>
        Add Note
      </Button>
      <GroupActionAddNoteDialog groupAction={groupAction} />
    </div>
  );
};
