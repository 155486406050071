import { z } from 'zod';

export const crisisResponseBehavioralSupportSchema = z.object({
  school: z
    .string({ invalid_type_error: 'School must be a string.' })
    .trim()
    .min(1, { message: 'School is required.' }),
  name: z
    .string({ invalid_type_error: 'Activity Name must be a string.' })
    .trim()
    .min(1, { message: 'Activity Name is required.' }),
  date_and_time: z.coerce
    .date({ required_error: 'Date is required.', message: 'Date is invalid.' })
    .refine((date) => date > new Date(), { message: 'Date must be in the future' }),
  duration: z
    .number({ message: 'Duration must be an integer.' })
    .int()
    .gt(0, { message: 'Duration must be greater than 0.' })
    .max(720, { message: 'Duration must be less than 720 minutes.' }),
  type: z.string({ invalid_type_error: 'Type must be a string.' }).trim().min(1, { message: 'Type is required.' }),
  topic: z.string({ invalid_type_error: 'Type must be a string.' }),
  stakeholders_served: z.array(z.string()).min(1, { message: 'At least one stakeholder must be served.' }),
  participants_count: z
    .number({ message: 'Number of Participants must be an integer.' })
    .int()
    .gt(0, { message: 'Number of Participants must be greater than 0.' }),
  session_participant: z
    .string({ invalid_type_error: 'Session Participants must be a string.' })
    .trim()
    .min(1, { message: 'Session Participants is required.' }),
  tags: z.array(z.string()),
  description: z.string().trim(),
  crisis_mode_of_support: z
    .string({ invalid_type_error: 'Mode of Support must be a string.' })
    .trim()
    .min(1, { message: 'Mode of Support is required.' }),
  crisis_internal_comments: z.string(),
});
