import { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePaginate, useSearch } from '@purple/hooks';
import { SORT_QUERY_NAME } from '@purple/shared-types';
import { Heading, TableFooterSection } from '@purple/ui';
import { DataTable, DataTableSkeleton } from '~/components';
import { DeleteStudentFileModal } from '~/components/Modals/StudentsFilesModals/DeleteStudentFileModal';
import { EditStudentFileModal } from '~/components/Modals/StudentsFilesModals/EditStudentFile';
import { useModal } from '~/hooks';
import { useFiles } from '~/queries';
import { FilesHeaderSection } from './FIlesHeaderSection';
import { UploadFilePreview } from './UploadFilePreview';
import { useFilesColumns } from './useFilesColumns';

export const FilesTab = () => {
  const { studentId } = useParams();

  const [searchParameters] = useSearchParams();

  const { debounceSearch, search } = useSearch();

  const sort = searchParameters.get(SORT_QUERY_NAME) || '';
  const { limit, offset, onPageChange, page, onLimitChange } = usePaginate();

  const { isOpen } = useModal('edit-student-file');

  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const filesColumns = useFilesColumns({ onSelectFileId: setSelectedFileId, onSelectFile: setUploadedFile });

  const { data, isLoading } = useFiles({
    limit,
    offset,
    search: debounceSearch,
    ordering: sort,
    student: studentId as string,
  });

  const isDataNotExist = (!data?.results || data.results.length === 0) && !isLoading;

  const pageCount = useMemo(() => {
    return data?.count ? Math.ceil(data.count / limit) : 1;
  }, [data?.count, limit]);

  if (!studentId) {
    return null;
  }

  return (
    <>
      <div className="flex px-4 pb-4 pt-6">
        <Heading type="heading-600" variant="size-18" className="text-grey-title">
          Files
        </Heading>
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex flex-row px-4">
          <FilesHeaderSection onUploadFile={setUploadedFile} />
        </div>
        <div>
          <DataTable
            columns={filesColumns}
            data={data?.results || []}
            isLoading={isLoading}
            isFiltersApplied={Boolean(search)}
            emptyStateTitle="No files found"
            emptyStateMessage="There are no files to display. If it an error, please contact support."
            skeleton={<DataTableSkeleton rows={limit} />}
          />
          {!isDataNotExist && (
            <TableFooterSection
              currentPage={page}
              pageCount={pageCount}
              onPageChange={onPageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={onLimitChange}
              totalRows={data?.count || 0}
            />
          )}
        </div>
      </div>
      <UploadFilePreview
        uploadedFile={uploadedFile}
        selectedFileId={selectedFileId}
        setUploadedFile={setUploadedFile}
        setSelectedFileId={setSelectedFileId}
      />
      <DeleteStudentFileModal selectedFileId={selectedFileId} setSelectedFileId={setSelectedFileId} />
      {isOpen && <EditStudentFileModal selectedFileId={selectedFileId} setSelectedFileId={setSelectedFileId} />}
    </>
  );
};
